import React, { useCallback, useState } from "react";
import Helmet from "react-helmet";
import { useNavigate } from "react-router";

import { Opportunity } from "@pricing-tool/lib/opportunity/core";

import {
  Client,
  useCreateOpportunityMutation,
} from "@pricing-tool/graphql/lib/react";

import OpportunityForm from "../../../components/opportunity/forms/OpportunityForm";
import LoadingOverlay from "../../../components/common/LoadingOverlay";
import ErrorBanner from "../../../components/common/ErrorBanner";
import CreateClientModal from "../../../components/client/modals/CreateClientModal";

function CreateOpportunity() {
  const navigate = useNavigate();

  const [showCreateClientModal, setShowCreateClientModal] = useState(false);
  const [createdClient, setCreatedClient] = useState<Client | undefined>();

  const [createOpportunity, { loading, error }] =
    useCreateOpportunityMutation();

  const onSubmit = useCallback(
    (opportunity: Opportunity) => {
      createOpportunity({
        variables: {
          branchId: "branch-1",
          input: {
            branchId: "branch-1",
            name: opportunity.name,
            clientId: opportunity.client!.id,
            status: opportunity.status,
            closeDate: opportunity.closeDate,
            pricingDate: opportunity.pricingDate,
            scenarios: [],
          },
        },
      }).then((createdOpportunity) => {
        if (createdOpportunity.data?.createOpportunity) {
          navigate(
            `/opportunities/${createdOpportunity.data.createOpportunity.id}`,
          );
        }
      });
    },
    [createOpportunity, navigate],
  );

  const onCancel = () => {
    navigate(-1);
  };

  const onClientCreated = (client: Client) => {
    setCreatedClient(client);
    setShowCreateClientModal(false);
  };

  return (
    <div className="page create-opportunity">
      <Helmet>
        <title>Create Opportunity</title>
      </Helmet>
      <div className="container max-w-xl mx-auto">
        <div className="mb-6">
          <h1 className="text-2xl font-semibold tracking-tight">
            Create Opportunity
          </h1>
          <p className="text-sm text-muted-foreground">
            Fill in the opportunity details below
          </p>
        </div>

        <div className="rounded-lg bg-card border p-8">
          <OpportunityForm
            client={createdClient}
            onCancel={onCancel}
            onSubmit={onSubmit}
            submitButtonLabel="Create"
            onCreateClient={() => setShowCreateClientModal(true)}
          />

          {showCreateClientModal && (
            <CreateClientModal
              onClose={() => setShowCreateClientModal(false)}
              onCreated={onClientCreated}
            />
          )}
          {loading && <LoadingOverlay text="Creating opportunity" />}
          {error && <ErrorBanner text={error.message} />}
        </div>
      </div>
    </div>
  );
}

export default CreateOpportunity;
