import React from "react";

import Modal, { ModalFooter } from "../../../common/Modal";
import { RemoveTreasuryServicesModalProps } from "./props";
import { Button } from "@/components/ui/button";

const RemoveTreasuryServicesModal = (
  props: RemoveTreasuryServicesModalProps,
) => {
  return (
    <Modal
      title="Remove Treasury Service"
      width={600}
      contentHeight={250}
      onClose={props.onCancel}
    >
      <div className="confirm-remove-elements-form">
        <div className="body overflow-auto">
          <div className="m-3">
            <p className="mb-2">
              You are trying to remove "{props.element.name}" (
              {props.element.id}), but it is a prerequisite for:
            </p>
            <ul className="list-disc list-inside mb-2">
              {props.dependentElements.map((element) => (
                <li key={element.id}>
                  {element.name} ({element.id})
                </li>
              ))}
            </ul>
            <p>Do you want to remove all of them?</p>
          </div>
        </div>
        <ModalFooter className="flex gap-4 justify-end">
          <Button variant="secondary" onClick={props.onCancel}>
            Cancel
          </Button>
          <Button onClick={props.onConfirm}>Yes</Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

export default RemoveTreasuryServicesModal;
