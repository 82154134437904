import React from "react";
import { sortBy } from "ramda";
import moment from "moment";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { Link } from "react-router-dom";
import { useLocation, useParams } from "react-router";
import { Minus } from "lucide-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { DocumentSummaryFragment } from "@pricing-tool/graphql/lib/react";

import { DataTable } from "@/components/ui/data-table";
import { Button } from "@/components/ui/button";

import "./styles.scss";

export type DocumentsTableProps = {
  documents: DocumentSummaryFragment[];
  onDownload: (document: DocumentSummaryFragment) => void;
  onDelete: (document: DocumentSummaryFragment) => void;
};

const docTypeToIcon: { [key: string]: IconName } = {
  "application/pdf": "file-pdf",
  "application/msword": "file-word",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "file-word",
  "application/vnd.ms-excel": "file-excel",
  "image/jpeg": "file-image",
  "image/png": "file-image",
  "text/plain": "file-lines",
  "text/csv": "file-csv",
  "application/zip": "file-zipper",
  "application/vnd.ms-powerpoint": "file-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    "file-powerpoint",
};

const getIcon = (type: string) => {
  return docTypeToIcon[type] || "file";
};

const DocumentsTable = ({
  documents,
  onDownload,
  onDelete,
}: DocumentsTableProps) => {
  const { opportunityId } = useParams();
  const location = useLocation();

  let state = location.state as { backgroundLocation?: Location } | null;

  if (!documents || documents.length === 0) {
    return <div className="p-4">No documents</div>;
  }

  const sortedDocuments = sortBy((d) => d?.name || 1, documents);

  const columnHelper = createColumnHelper<DocumentSummaryFragment>();

  const columns: ColumnDef<DocumentSummaryFragment, any>[] = [
    columnHelper.accessor("documentType", {
      cell: (document) => (
        <Button
          variant={"ghost"}
          className="h-8 w-8 p-0"
          onClick={() => onDownload(document.row.original)}
        >
          <FontAwesomeIcon icon={["fas", getIcon(document.getValue())]} />
        </Button>
      ),
      header: "",
      size: 6,
    }),
    columnHelper.accessor("name", {
      cell: (document) => (
        <Link
          to={`/opportunities/${opportunityId}/documents/${document.row.original.id}`}
          state={state}
        >
          {document.getValue()}
        </Link>
      ),
      header: "Name",
    }),
    columnHelper.accessor("description", {
      cell: (document) => document.getValue(),
      header: "Description",
    }),
    columnHelper.accessor("updatedAt", {
      cell: (document) => moment(document.getValue()).format("LLL"),
      header: "Last Updated At",
    }),
    columnHelper.accessor("updatedBy", {
      cell: (document) => document.getValue(),
      header: "Last Updated By",
    }),
    columnHelper.display({
      id: "actions",
      cell: (document) => (
        <Button
          variant={"ghost"}
          className="h-8 w-8 p-0 text-right text-gray-300 hover:text-red-300"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onDelete(document.row.original);
          }}
        >
          <Minus />
        </Button>
      ),
      header: "",
      size: 6,
    }),
  ];

  return (
    <div className="component documents-table">
      <DataTable columns={columns} data={sortedDocuments} />
    </div>
  );
};

export default DocumentsTable;
