import React from "react";

import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from "@/components/ui/sidebar";

import "./styles.scss";
import { ArrowRightToLine, MessageCircle } from "lucide-react";
import Conversation from "@/src/components/opportunity/forms/Conversation";

export type RightDockProps = {
  branchId: string;
  opportunityId: string;
};

const RightDock = ({ branchId, opportunityId }: RightDockProps) => {
  const [activeItem, setActiveItem] =
    React.useState<"conversation">("conversation");
  const { setOpen, open } = useSidebar();

  return (
    <Sidebar
      variant="sidebar"
      collapsible="icon"
      side="right"
      className="overflow-hidden [&>[data-sidebar=sidebar]]:flex-row mt-12"
    >
      {/*  This the icon bar */}
      <Sidebar
        collapsible="none"
        side="right"
        className="!w-[calc(var(--sidebar-width-icon)_+_1px)] border-r "
      >
        <SidebarContent>
          <SidebarGroup>
            <SidebarGroupContent className="px-1.5 md:px-0">
              <SidebarMenu>
                <SidebarMenuItem>
                  <SidebarMenuButton
                    tooltip={{
                      children: "Conversation",
                      hidden: false,
                    }}
                    onClick={() => {
                      setActiveItem("conversation");
                      setOpen(true);
                    }}
                    isActive={activeItem === "conversation"}
                    className="rounded"
                  >
                    <MessageCircle />
                  </SidebarMenuButton>
                </SidebarMenuItem>
              </SidebarMenu>
            </SidebarGroupContent>
          </SidebarGroup>
        </SidebarContent>
        <SidebarFooter>
          {open && (
            <SidebarMenuButton
              onClick={() => setOpen(false)}
              className="rounded"
            >
              <ArrowRightToLine />
            </SidebarMenuButton>
          )}
        </SidebarFooter>
      </Sidebar>

      {/* This is the content */}
      <Sidebar collapsible="none" className="hidden flex-1 md:flex">
        <SidebarHeader className="gap-3.5 border-b p-4">
          <div className="text-base font-medium text-foreground">
            {activeItem === "conversation" && "Conversation"}
          </div>
        </SidebarHeader>
        <SidebarContent>
          <SidebarGroup className="p-0 h-full">
            <SidebarGroupContent className="h-full">
              {activeItem === "conversation" && (
                <Conversation
                  branchId={branchId}
                  opportunityId={opportunityId}
                />
              )}
            </SidebarGroupContent>
          </SidebarGroup>
        </SidebarContent>
      </Sidebar>
    </Sidebar>
  );
};

export default RightDock;
