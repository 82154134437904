import Decimal from "decimal.js-light";
import { findLast } from "ramda";
import { Schema as S } from "@effect/schema";
import moment from "moment";

import { Index, IndexSchema } from "./IndexRates";
import { DecimalFromString } from "../../../util/Decimal";

export const ForwardCurveSchema = S.Struct({
  date: S.Date,
  curve: S.Array(
    S.Struct({
      month: S.String,
      rate: DecimalFromString,
    }),
  ),
});
export const ForwardCurvesSchema = S.Record({
  key: IndexSchema,
  value: ForwardCurveSchema,
});
export type ForwardCurves = S.Schema.Type<typeof ForwardCurvesSchema>;

export const ForwardCurvesSnapshotSchema = S.Struct({
  forwardCurves: ForwardCurvesSchema,
  createdAt: S.Date,
});
export type ForwardCurvesSnapshot = S.Schema.Type<
  typeof ForwardCurvesSnapshotSchema
>;

export const getRateAtDate = (
  curve: ForwardCurves,
  index: Index,
  date: Date,
): Decimal | undefined => {
  const forwardCurveForIndex = curve[index];
  if (!forwardCurveForIndex) return undefined;

  const { date: curveDate, curve: curveData } = forwardCurveForIndex;
  const monthDifference = moment(date).diff(moment(curveDate), "months");

  const firstMonthDifference = parseInt(curveData[0].month);

  if (monthDifference < firstMonthDifference) {
    return undefined;
  }

  const rate = findLast(
    (row) => parseInt(row.month) >= monthDifference,
    curveData,
  );

  return rate ? rate.rate : curveData[curveData.length - 1].rate;
};
