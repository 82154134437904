import React, { useEffect, useState } from "react";

import {
  RenderedTemplate,
  TemplateSummaryFragment,
} from "@pricing-tool/graphql/lib/react";

import TemplatePicker from "../../fields/TemplatePicker";
import CreateRenderedTemplate from "../../fields/CreateRenderedTemplate";
import { ModalFooter } from "@/src/components/common/Modal";
import { Button } from "@/components/ui/button";

import "./styles.scss";

export type CreateDocumentWorkflowProps = {
  opportunityId: string;
  scenarioId: string;
  onCancel: () => void;
};

const CreateDocumentWorkflow = ({
  opportunityId,
  scenarioId,
  onCancel,
}: CreateDocumentWorkflowProps) => {
  const [workflowState, setWorkflowState] = useState<
    "template" | "create" | "download"
  >("template");
  const [selectedTemplate, setSelectedTemplate] = useState<
    TemplateSummaryFragment | undefined
  >();
  const [renderedTemplate, setRenderedTemplate] = useState<
    RenderedTemplate | undefined
  >();

  useEffect(() => {
    if (!selectedTemplate) {
      setWorkflowState("template");
      return;
    }
    setWorkflowState("create");
  }, [selectedTemplate]);

  useEffect(() => {
    if (workflowState !== "create") return;
    if (!renderedTemplate) {
      setWorkflowState("template");
      return;
    }
    setWorkflowState("download");
  }, [renderedTemplate]);

  return (
    <div className="component create-document-workflow">
      {workflowState === "template" && (
        <TemplatePicker onConfirm={setSelectedTemplate} onCancel={onCancel} />
      )}

      {workflowState === "create" && (
        <CreateRenderedTemplate
          templateId={selectedTemplate!.id}
          opportunityId={opportunityId}
          scenarioId={scenarioId}
          onCancel={onCancel}
          onBack={() => {
            setWorkflowState("template");
            setSelectedTemplate(undefined);
          }}
          onComplete={setRenderedTemplate}
        />
      )}

      {workflowState === "download" && (
        <div className="download">
          <div className="body flex justify-center items-center">
            <a
              href={renderedTemplate!.documentSignedUrl}
              target="_blank"
              rel="noreferrer"
            >
              Download
            </a>
          </div>
          <ModalFooter className="flex w-full justify-end">
            <Button variant="outline" onClick={onCancel}>
              Cancel
            </Button>
          </ModalFooter>
        </div>
      )}
    </div>
  );
};

export default CreateDocumentWorkflow;
