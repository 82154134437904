import { BehaviorSubject, combineLatest, map, Observable, share } from "rxjs";
import Decimal from "decimal.js-light";
import { match } from "ts-pattern";
import moment from "moment";
import { last, prop, reduce } from "ramda";

import { OnlyDecimal } from "../../../util/Decimal";
import {
  AmortizationSchedule,
  AmortizationSummary,
  CommercialIndustrialDependencies,
  InterestCalculationMethod,
  Payment,
  PaymentFrequency,
  Payments,
  PaymentType,
  RateType,
} from "../core";
import {
  ForwardCurves,
  ForwardCurvesSnapshot,
  getRateAtDate,
} from "../core/ForwardCurves";
import { Index } from "../core/IndexRates";

import anyChanged from "../../../util/operators/anyChanged";
import fieldChanged from "../../../util/operators/fieldChanged";
import mapAllDefined from "../../../util/operators/mapAllDefined";

export const displayPaymentType = (
  paymentType: PaymentType | null | undefined,
): string =>
  paymentType
    ? match(paymentType)
        .with("straight_line", () => "Straight Line")
        .with("mortgage_amortization", () => "Mortgage Amortization")
        .exhaustive()
    : "--";

export const fixed = (): RateType => ({ _type: "fixed" });
export const floating = (): RateType => ({ _type: "floating" });

export const displayRateType = (rateType: RateType): string =>
  rateType
    ? match(rateType)
        .with({ _type: "fixed" }, () => "Fixed")
        .with({ _type: "floating" }, () => "Floating")
        .with(
          { _type: "adjustable" },
          (rt) =>
            `Adjustable (fixed for ${rt.fixedPeriod} years, adjusts every ${rt.adjustmentPeriod} years)`,
        )
        .exhaustive()
    : "--";

export const displayPaymentFrequency = (paymentFrequency: PaymentFrequency) =>
  paymentFrequency
    ? match(paymentFrequency)
        .with("annual", () => "Annual")
        .with("semi_annual", () => "Semi-Annual")
        .with("quarterly", () => "Quarterly")
        .with("bi_monthly", () => "Bi-Monthly")
        .with("monthly", () => "Monthly")
        .with("semi_monthly", () => "Semi-Monthly")
        .with("bi_weekly", () => "Bi-Weekly")
        .with("weekly", () => "Weekly")
        .exhaustive()
    : "--";

export const displayInterestCalculationMethod = (
  interestCalculationMethod: InterestCalculationMethod,
) =>
  interestCalculationMethod
    ? match(interestCalculationMethod)
        .with("30/360", () => "30/360")
        .with("actual/360", () => "Actual/360")
        .with("actual/365", () => "Actual/365")
        .exhaustive()
    : "--";

export type AmortizationInput = {
  loanAmount: Observable<Decimal | undefined>;
  interestRate: Observable<Decimal | undefined>;
  index: Observable<Index | undefined>;
  spread: Observable<Decimal | undefined>;
  termYears: BehaviorSubject<number | undefined>;
  amortizationYears: BehaviorSubject<number | undefined>;
  interestCalculationMethod: BehaviorSubject<
    InterestCalculationMethod | undefined
  >;
  paymentFrequency: BehaviorSubject<PaymentFrequency | undefined>;
  paymentType: BehaviorSubject<PaymentType | undefined>;
  rateType: BehaviorSubject<RateType | undefined>;
  forwardCurvesSnapshot: Observable<ForwardCurvesSnapshot | undefined>;
};

export type AmortizationOutput = {
  summary: Observable<AmortizationSummary | undefined>;
  amortizationSchedule: Observable<AmortizationSchedule | undefined>;
};

export type AmortizationModel = {
  input: AmortizationInput;
  output: AmortizationOutput;
};

export const trackChanges = (input: AmortizationInput): Observable<boolean> =>
  anyChanged([
    fieldChanged(input.loanAmount),
    fieldChanged(input.paymentType),
    fieldChanged(input.paymentFrequency),
    fieldChanged(input.rateType),
    fieldChanged(input.interestCalculationMethod),
    fieldChanged(input.termYears),
    fieldChanged(input.amortizationYears),
  ]);

const getNumberOfPaymentsPerYear = (
  paymentFrequency: PaymentFrequency,
): number => {
  return match(paymentFrequency)
    .with("monthly", () => 12)
    .otherwise(() => {
      throw Error("not yet implemented");
    });
};

const getPaymentDate = (
  paymentFrequency: PaymentFrequency,
  startDate: moment.Moment,
  iteration: number,
): moment.Moment => {
  return match(paymentFrequency)
    .with("monthly", () => startDate.add(iteration, "months"))
    .otherwise(() => {
      throw Error("not yet implemented");
    });
};

const getInterestRateAtDate = (
  date: Date,
  rateType: RateType,
  index: Index,
  interestRate: Decimal,
  spread: Decimal,
  forwardCurve: ForwardCurves,
): Decimal | undefined => {
  return match(rateType)
    .with({ _type: "fixed" }, (_) => interestRate)
    .with({ _type: "floating" }, () => {
      const forwardRate = getRateAtDate(forwardCurve, index, date);
      return forwardRate ? forwardRate.plus(spread) : undefined;
    })
    .with({ _type: "adjustable" }, (_) => {
      throw Error("not yet implemented");
    })
    .exhaustive();
};

const calculateInterestPayment = (
  paymentFrequency: PaymentFrequency,
  beginningBalance: Decimal,
  annualInterestRate: Decimal | undefined,
  interestCalculationMethod: InterestCalculationMethod,
): Decimal | undefined => {
  return match([paymentFrequency, interestCalculationMethod])
    .with(["monthly", "30/360"], () => {
      if (!annualInterestRate) return undefined;
      const interestPayment = beginningBalance.mul(
        annualInterestRate.dividedBy(12),
      );
      return interestPayment.isNegative() ? new Decimal(0) : interestPayment;
    })
    .otherwise(() => {
      throw Error("not yet implemented");
    });
};

const numberOfInterestDays = (
  interestCalculationMethod: InterestCalculationMethod,
): number => {
  return match(interestCalculationMethod)
    .with("30/360", () => 360)
    .with("actual/360", () => 360)
    .with("actual/365", () => 365)
    .exhaustive();
};

export const generateLinearSchedule = (
  _loanAmount: Decimal,
  _index: Index,
  _interestRate: Decimal,
  _spread: Decimal,
  _startDate: Date,
  _termYears: number,
  _amortizationYears: number,
  _interestCalculationMethod: InterestCalculationMethod,
  _paymentFrequency: PaymentFrequency,
  _rateType: RateType,
  _forwardCurve?: ForwardCurves,
) => {
  throw Error("not yet implemented");
};

export const generateAmortizationSchedule = (
  loanAmount: Decimal,
  index: Index,
  interestRate: Decimal,
  spread: Decimal,
  startDate: Date,
  termYears: number,
  amortizationYears: number,
  interestCalculationMethod: InterestCalculationMethod,
  paymentFrequency: PaymentFrequency,
  rateType: RateType,
  forwardCurve: ForwardCurves,
): AmortizationSchedule | undefined => {
  const paymentsPerYear = getNumberOfPaymentsPerYear(paymentFrequency);
  const totalNumberOfPayments = paymentsPerYear * amortizationYears;
  const totalNumberOfPaymentsUntilMaturity = paymentsPerYear * termYears;

  const calculatePayment = (
    balance: Decimal,
    rate: Decimal,
    remainingPayments: number,
  ): Decimal => {
    const intervalRate = rate.dividedBy(paymentsPerYear);
    const payment = balance
      .mul(intervalRate)
      .mul(intervalRate.plus(1).pow(remainingPayments))
      .dividedBy(intervalRate.plus(1).pow(remainingPayments).minus(1));

    return payment.isNegative() ? new Decimal(0) : payment;
  };

  let balance = loanAmount;
  let loanStartDate = moment(startDate);
  let lastPaymentDate = moment(startDate);

  const amortizationSchedule: AmortizationSchedule = [];

  let currentInterestRate = interestRate;
  let payment = calculatePayment(
    balance,
    currentInterestRate,
    totalNumberOfPayments,
  );

  for (let i = 0; i < totalNumberOfPaymentsUntilMaturity; i++) {
    const date = getPaymentDate(paymentFrequency, loanStartDate.clone(), i);
    const daysSinceLastPayment = date.diff(lastPaymentDate, "days");
    const beginningBalance = balance;

    const interestRateAtDate = getInterestRateAtDate(
      date.toDate(),
      rateType,
      index,
      interestRate,
      spread,
      forwardCurve,
    );
    if (!interestRateAtDate) return undefined;

    if (!interestRateAtDate.equals(currentInterestRate)) {
      currentInterestRate = interestRateAtDate;
      const remainingPayments = totalNumberOfPayments - i;
      payment = calculatePayment(
        balance,
        currentInterestRate,
        remainingPayments,
      );
    }

    const interest = calculateInterestPayment(
      paymentFrequency,
      beginningBalance,
      interestRateAtDate,
      interestCalculationMethod,
    );
    if (!interest) return undefined;

    const principal = payment.minus(interest);
    const endingBalance = beginningBalance.minus(principal);
    const balloon = new Decimal(0);

    amortizationSchedule.push({
      date: date.startOf("day").toDate(),
      daysSinceLastPayment,
      interestRate: interestRateAtDate,
      beginningBalance,
      payment,
      interest,
      principal,
      endingBalance,
      balloon,
    });

    balance = endingBalance;
    lastPaymentDate = date;
  }

  if (balance.comparedTo(0) !== 0) {
    const lastPayment = last(amortizationSchedule);
    if (lastPayment) {
      lastPayment!.balloon = balance;
    }
  }

  return amortizationSchedule;
};

export const generateSummary = (
  loanAmount: Decimal,
  interestRate: Decimal,
  interestCalculationMethod: InterestCalculationMethod,
  paymentFrequency: PaymentFrequency,
  amortizationSchedule: AmortizationSchedule | undefined,
): AmortizationSummary | undefined => {
  if (!amortizationSchedule) return undefined;

  const sumProp = (propName: keyof OnlyDecimal<Payment>) =>
    reduce(
      (acc, elem) =>
        prop(propName, elem) ? acc.plus(prop(propName, elem)) : acc,
      new Decimal(0),
      amortizationSchedule,
    );

  const numberOfPayments = amortizationSchedule.length;
  const balloonPaid = sumProp("balloon");
  const totalPaid = sumProp("payment");
  const interestPaid = sumProp("interest");
  const averageBalance =
    sumProp("beginningBalance").dividedBy(numberOfPayments);

  const effectiveInterestRate = interestRate
    .dividedBy(numberOfInterestDays(interestCalculationMethod))
    .plus(1)
    .pow(
      new Decimal(365).dividedBy(getNumberOfPaymentsPerYear(paymentFrequency)),
    )
    .minus(1);

  return {
    effectiveInterestRate,
    numberOfPayments,
    balloonPaid,
    totalPaid,
    interestPaid,
    averageBalance,
  };
};

export const calculate = (
  input: AmortizationInput,
  dependencies: CommercialIndustrialDependencies,
): AmortizationOutput => {
  const {
    loanAmount,
    index,
    interestRate,
    spread,
    termYears,
    amortizationYears,
    interestCalculationMethod,
    paymentFrequency,
    paymentType,
    rateType,
    forwardCurvesSnapshot,
  } = input;
  const { startDate } = dependencies;

  const amortizationSchedule = combineLatest([
    loanAmount,
    index,
    interestRate,
    spread,
    termYears,
    amortizationYears,
    interestCalculationMethod,
    paymentFrequency,
    paymentType,
    rateType,
    forwardCurvesSnapshot,
  ]).pipe(
    mapAllDefined(
      ([
        loanAmount,
        index,
        interestRate,
        spread,
        termYears,
        amortizationYears,
        interestCalculationMethod,
        paymentFrequency,
        paymentType,
        rateType,
        forwardCurvesSnapshot,
      ]) => {
        return paymentType === "mortgage_amortization"
          ? generateAmortizationSchedule(
              loanAmount,
              index,
              interestRate,
              spread,
              startDate,
              termYears,
              amortizationYears,
              interestCalculationMethod,
              paymentFrequency,
              rateType,
              forwardCurvesSnapshot?.forwardCurves,
            )
          : generateLinearSchedule(
              loanAmount,
              index,
              interestRate,
              spread,
              startDate,
              termYears,
              amortizationYears,
              interestCalculationMethod,
              paymentFrequency,
              rateType,
              forwardCurvesSnapshot?.forwardCurves,
            );
      },
    ),
  );

  const summary = combineLatest([
    loanAmount,
    interestRate,
    interestCalculationMethod,
    paymentFrequency,
    amortizationSchedule,
  ]).pipe(
    mapAllDefined(
      ([
        loanAmount,
        interestRate,
        interestCalculationMethod,
        paymentFrequency,
        amortizationSchedule,
      ]) => {
        return generateSummary(
          loanAmount,
          interestRate,
          interestCalculationMethod,
          paymentFrequency,
          amortizationSchedule,
        );
      },
    ),
  );

  return {
    summary,
    amortizationSchedule,
  };
};

const defaults: Partial<Payments> = {
  interestCalculationMethod: "30/360",
  paymentFrequency: "monthly",
  paymentType: "mortgage_amortization",
  rateType: fixed(),
};

export const fromPayments = (
  payments: Payments | undefined,
  loanAmount: Observable<Decimal | undefined>,
  index: Observable<Index | undefined>,
  interestRate: Observable<Decimal | undefined>,
  spread: Observable<Decimal | undefined>,
  forwardCurvesSnapshot: Observable<ForwardCurvesSnapshot | undefined>,
  dependencies: CommercialIndustrialDependencies,
): AmortizationModel => {
  const input: AmortizationInput = {
    loanAmount,
    index,
    interestRate,
    spread,
    termYears: new BehaviorSubject<number | undefined>(payments?.termYears),
    amortizationYears: new BehaviorSubject<number | undefined>(
      payments?.amortizationYears,
    ),
    interestCalculationMethod: new BehaviorSubject<
      InterestCalculationMethod | undefined
    >(
      payments?.interestCalculationMethod || defaults.interestCalculationMethod,
    ),
    paymentFrequency: new BehaviorSubject<PaymentFrequency | undefined>(
      payments?.paymentFrequency || defaults.paymentFrequency,
    ),
    paymentType: new BehaviorSubject<PaymentType | undefined>(
      payments?.paymentType || defaults.paymentType,
    ),
    rateType: new BehaviorSubject<RateType | undefined>(
      payments?.rateType || defaults.rateType,
    ),
    forwardCurvesSnapshot,
  };

  const output = calculate(input, dependencies);

  return {
    input,
    output,
  };
};

export const asObservable = (
  model: AmortizationModel,
): Observable<Payments> => {
  const {
    termYears,
    amortizationYears,
    interestCalculationMethod,
    paymentFrequency,
    paymentType,
    rateType,
  } = model.input;
  const { summary, amortizationSchedule } = model.output;

  return combineLatest([
    termYears,
    amortizationYears,
    interestCalculationMethod,
    paymentFrequency,
    paymentType,
    rateType,
    summary,
    amortizationSchedule,
  ]).pipe(
    map(
      ([
        termYears,
        amortizationYears,
        interestCalculationMethod,
        paymentFrequency,
        paymentType,
        rateType,
        summary,
        amortizationSchedule,
      ]) => ({
        termYears,
        amortizationYears,
        interestCalculationMethod,
        paymentFrequency,
        paymentType,
        rateType,
        amortizationSummary: summary,
        amortizationSchedule,
      }),
    ),
    share(),
  );
};
