import Decimal from "decimal.js-light";
import moment from "moment";

import { IndexSchema } from "../core/IndexRates";

const AMERIBOR = IndexSchema.make("AMERIBOR");
const DFF = IndexSchema.make("DFF");
const SOFR = IndexSchema.make("SOFR");

export default {
  [AMERIBOR]: {
    date: moment("2022-07-01").toDate(),
    curve: [{ month: "0", rate: new Decimal(0.027) }],
  },
  [DFF]: {
    date: moment("2022-07-01").toDate(),
    curve: [{ month: "0", rate: new Decimal(0.0319) }],
  },
  [SOFR]: {
    date: moment("2022-07-01").toDate(),
    curve: [{ month: "0", rate: new Decimal(0.0228) }],
  },
};
