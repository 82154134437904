import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";

import "./styles.scss";
import { ModalFooter } from "@/src/components/common/Modal";

export interface DocumentFormValue {
  name: string;
  description?: string;
  file?: File;
}

const defaultDocumentFormValue: DocumentFormValue = {
  name: "",
  description: "",
  file: undefined,
};

export interface DocumentFormProps {
  document?: DocumentFormValue;
  onCancel: () => void;
  onSubmit: (input: DocumentFormValue) => void;
  onDelete?: () => void;
  onDownloadFile?: () => void;
}

const DocumentForm: React.FC<DocumentFormProps> = (props) => {
  const { document, onCancel, onSubmit, onDelete, onDownloadFile } = props;

  const form = useForm<DocumentFormValue>({
    defaultValues: document || defaultDocumentFormValue,
  });
  const { setValue, handleSubmit } = form;

  useEffect(() => {
    if (!document) return;
    setValue("name", document?.name);
    setValue("description", document?.description);
  }, [props.document]);

  return (
    <div className="component document-form">
      <Form {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="body">
            <FormField
              control={form.control}
              name="name"
              rules={{ required: "Required" }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Enter a name for the document"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="description"
              rules={{ required: "Required" }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <Input
                      type="textarea"
                      placeholder="Description of the document"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="file"
              rules={{ required: "Required" }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel htmlFor="file">File</FormLabel>
                  {onDownloadFile && (
                    <div>
                      <Button
                        variant="link"
                        size="inline"
                        onClick={onDownloadFile}
                      >
                        Download current file
                      </Button>
                    </div>
                  )}
                  <FormControl>
                    <Input type="file" id="file" onChange={field.onChange} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <ModalFooter className="flex justify-between">
            <div>
              {props.onDelete && (
                <Button type="button" variant="destructive" onClick={onDelete}>
                  Delete
                </Button>
              )}
            </div>
            <div className="flex gap-4">
              <Button variant="outline" type="button" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </div>
          </ModalFooter>
        </form>
      </Form>
    </div>
  );
};

export default DocumentForm;
