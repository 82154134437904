import React from "react";
import { sortBy } from "ramda";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";

import { ClientFragment } from "@pricing-tool/graphql/lib/react";

import { DataTable } from "@/components/ui/data-table";
import { Button } from "@/components/ui/button";

import "./styles.scss";

export type ClientsTableProps = {
  clients: ClientFragment[];
  onSelect: (client: ClientFragment) => void;
};

const ClientsTable = ({ clients, onSelect }: ClientsTableProps) => {
  if (!clients || clients.length === 0) {
    return <div className="p-4">No clients</div>;
  }

  const sortedClients = sortBy((c) => c?.name || 1, clients);

  const columnHelper = createColumnHelper<ClientFragment>();

  const columns: ColumnDef<ClientFragment, any>[] = [
    columnHelper.accessor("name", {
      cell: (client) => (
        <Button
          size={"inline"}
          variant={"link"}
          onClick={() => onSelect(client.row.original)}
        >
          {client.getValue()}
        </Button>
      ),
      header: "Name",
    }),
    columnHelper.accessor("contact", {
      cell: (client) =>
        `${client.getValue()?.firstName || ""} ${
          client.getValue()?.lastName || ""
        }`,
      header: "Contact",
    }),
    columnHelper.accessor("contact.email", {
      cell: (client) => client.getValue(),
      header: "Email",
    }),
    columnHelper.accessor("contact.phone", {
      cell: (client) => client.getValue(),
      header: "Phone",
    }),
  ];

  return (
    <div className="component clients-table">
      <DataTable columns={columns} data={sortedClients} />
    </div>
  );
};

export default ClientsTable;
