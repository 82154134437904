import React, { useCallback } from "react";
import Decimal from "decimal.js-light";
import { Observable } from "rxjs";
import { useObservableState } from "observable-hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { CollateralModel } from "@pricing-tool/lib/products/ci/model/CollateralModel";
import { CollateralRecoveryTable } from "@pricing-tool/lib/products/ci/core/CollateralRecoveryTable";
import { fromCollateralType } from "@pricing-tool/lib/products/ci/model/CollateralItemModel";

import CollateralTable from "../CollateralTable";

import "./styles.scss";
import { Button } from "@/components/ui/button";
import { ModalFooter } from "@/src/components/common/Modal";

export type CollateralFormProps = {
  collateralModel: CollateralModel;
  collateralRecoveryTable: CollateralRecoveryTable;
  loanAmount: Observable<Decimal | undefined>;
  onCancel: () => void;
  onConfirm: () => void;
};

const CollateralForm = ({
  collateralModel,
  collateralRecoveryTable,
  loanAmount,
  onCancel,
  onConfirm,
}: CollateralFormProps) => {
  const [collateralItemModels] = useObservableState(
    () => collateralModel.input.collateralItems,
  );

  const addCollateralItem = useCallback(() => {
    if (!collateralItemModels) return;
    collateralModel.input.collateralItems.next([
      ...collateralItemModels,
      fromCollateralType(undefined, loanAmount, collateralRecoveryTable),
    ]);
  }, [
    collateralItemModels,
    loanAmount,
    collateralRecoveryTable,
  ]); /* eslint-disable-line react-hooks/exhaustive-deps */

  const removeCollateralItem = useCallback(
    (index: number) => {
      if (!collateralItemModels) return;
      // remove the item at index from collateralItemModels
      const newCollateralItemModels = collateralItemModels.filter(
        (_, i) => i !== index,
      );
      collateralModel.input.collateralItems.next(newCollateralItemModels);
    },
    [
      collateralItemModels,
    ] /* eslint-disable-line react-hooks/exhaustive-deps */,
  );

  return (
    <div className="collateral-form">
      <div className="flex m-3">
        <Button variant="outline" onClick={addCollateralItem}>
          <FontAwesomeIcon className="w-4 h-4 mr-2" icon="plus" />
          Add Collateral
        </Button>
      </div>
      <div className="body overflow-auto">
        <div className="collateral-table-wrapper">
          <CollateralTable
            collateralModel={collateralModel}
            collateralRecoveryTable={collateralRecoveryTable}
            onRemoveCollateralItem={removeCollateralItem}
          />
        </div>
      </div>
      <ModalFooter className="flex gap-4 justify-end">
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={onConfirm}>Done</Button>
      </ModalFooter>
    </div>
  );
};

export default CollateralForm;
