import React, { useCallback } from "react";
import Helmet from "react-helmet";
import { useNavigate } from "react-router";
import { ApolloError } from "@apollo/client";

import { useCreateClientMutation } from "@pricing-tool/graphql/lib/react";

import LoadingOverlay from "../../../components/common/LoadingOverlay";
import ErrorBanner from "../../../components/common/ErrorBanner";
import ClientForm, {
  ClientFormValue,
} from "../../../components/client/forms/ClientForm";

function CreateClient() {
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<ApolloError | null>(null);

  let [createClient] = useCreateClientMutation();

  const onSubmit = useCallback(
    (client: ClientFormValue) => {
      setLoading(true);
      createClient({
        variables: {
          branchId: "branch-1",
          input: {
            branchId: "branch-1",
            ...client,
          },
        },
      })
        .then((createdClient) => {
          if (!createdClient.data?.createClient) {
            setError(
              new ApolloError({
                errorMessage: "Failed to create client",
              }),
            );
            setLoading(false);
            return;
          }

          navigate(`/clients`);
        })
        .catch((err) => {
          console.log(err);
          setError(
            new ApolloError({
              errorMessage: "Failed to create client",
            }),
          );
          setLoading(false);
        });
    },
    [createClient, navigate],
  );

  const onCancel = () => {
    navigate("/clients");
  };

  return (
    <div className="page create-client">
      <Helmet>
        <title>Create Client</title>
      </Helmet>
      <div className="container max-w-xl mx-auto">
        <div className="mb-6">
          <h1 className="text-2xl font-semibold tracking-tight">
            Create Client
          </h1>
          <p className="text-sm text-muted-foreground">
            Fill in the client details below
          </p>
        </div>

        <div className="rounded-lg bg-card border p-8">
          <ClientForm onCancel={onCancel} onSubmit={onSubmit} />

          {loading && <LoadingOverlay text="Creating client" />}
          {error && <ErrorBanner text={error.message} />}
        </div>
      </div>
    </div>
  );
}

export default CreateClient;
