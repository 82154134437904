import React from "react";
import { useNavigate, useParams } from "react-router";
import Helmet from "react-helmet";
import Decimal from "decimal.js-light";
import { Pencil, Plus } from "lucide-react";

import {
  Address,
  useGetClientOpportunitiesQuery,
  useGetClientQuery,
} from "@pricing-tool/graphql/lib/react";

import { fromDto } from "@pricing-tool/lib/opportunitySummary/dto";
import { OpportunitySummary } from "@pricing-tool/lib/opportunitySummary/core";

import LoadingOverlay from "../../../components/common/LoadingOverlay";
import ErrorBanner from "../../../components/common/ErrorBanner";
import OpportunitiesTable from "../../../components/opportunity/tables/OpportunitiesTable";

import formatAddress from "../../../utils/formatAddress";
import formatName from "../../../utils/formatName";

import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";

// FIXME - don't hardcode
const performanceTargets = {
  returnOnEquity: new Decimal("0.1500"),
  returnOnAssets: new Decimal("0.0100"),
};

function ClientDetailsPage() {
  const { clientId } = useParams();
  const navigate = useNavigate();

  const { data, loading, error } = useGetClientQuery({
    variables: {
      branchId: "branch-1",
      clientId: clientId!,
    },
    fetchPolicy: "network-only",
  });

  const [opportunitySummaries, setOpportunitySummaries] = React.useState<
    OpportunitySummary[] | undefined
  >(undefined);
  const { loading: opportunitiesLoading, error: opportunitiesError } =
    useGetClientOpportunitiesQuery({
      variables: {
        branchId: "branch-1",
        clientId: clientId!,
      },
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        setOpportunitySummaries(data.getClientOpportunities.items.map(fromDto));
      },
    });

  return (
    <div className="page client-details flex">
      <Helmet>
        <title>Client Details</title>
      </Helmet>
      <div className="flex-1">
        <div className="text-2xl mb-4">Client Details</div>

        <Button
          variant="outline"
          onClick={() => navigate(`/clients/${clientId}/edit`)}
          className="mb-4"
        >
          <Pencil />
          Edit client
        </Button>

        {loading && <LoadingOverlay text="Fetching client" />}
        {error && <ErrorBanner text={error.message} />}
        {data && data.getClient && (
          <Card className="max-w-2xl">
            <CardContent className="grid gap-4 pt-6">
              <div className="flex justify-between">
                <dt className="text-sm font-medium text-muted-foreground">
                  Client Name:
                </dt>
                <dd className="text-sm">{data.getClient.name}</dd>
              </div>
              <div className="flex justify-between">
                <dt className="text-sm font-medium text-muted-foreground">
                  Address:
                </dt>
                <dd className="text-sm">
                  {formatAddress(data.getClient.address as Address)}
                </dd>
              </div>
              <div className="flex justify-between">
                <dt className="text-sm font-medium text-muted-foreground">
                  Contact:
                </dt>
                <dd className="text-sm">
                  {formatName(
                    data.getClient.contact?.firstName,
                    data.getClient.contact?.lastName,
                  )}
                </dd>
              </div>
              <div className="flex justify-between">
                <dt className="text-sm font-medium text-muted-foreground">
                  Phone:
                </dt>
                <dd className="text-sm">{data.getClient.contact?.phone}</dd>
              </div>
              <div className="flex justify-between">
                <dt className="text-sm font-medium text-muted-foreground">
                  Email:
                </dt>
                <dd className="text-sm">{data.getClient.contact?.email}</dd>
              </div>
            </CardContent>
          </Card>
        )}

        <div className="text-xl my-4">Opportunities</div>

        <Button
          variant="outline"
          onClick={() => navigate("/opportunities/new")}
        >
          <Plus />
          Create a new opportunity
        </Button>

        {opportunitiesLoading && <div>Loading opportunities...</div>}
        {opportunitiesError && (
          <ErrorBanner text={opportunitiesError.message} />
        )}
        {!opportunitiesLoading && !opportunitiesError && opportunitySummaries && (
          <div className="mt-4">
            <OpportunitiesTable
              opportunitySummaries={opportunitySummaries}
              performanceTargets={performanceTargets}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ClientDetailsPage;
