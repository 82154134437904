import { useEffect, useState } from "react";

import { Client } from "@pricing-tool/graphql/lib/react";

import useDebounce from "../../../../hooks/useDebounce";

import "./styles.scss";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { Check, ChevronsUpDown, PlusCircle } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@/components/ui/command";
import { cn } from "@/lib/utils";

export type ClientSearchProps = {
  selectedClient?: Client;
  searchTerm?: string;
  searching: boolean;
  searchResults: Client[];
  onSearchTermUpdated: (searchTerm: string) => void;
  onSearch: (searchTerm: string) => void;
  onSelectClient: (client: Client) => void;
  onCreateClient: () => void;
};

const ClientSearch = ({
  selectedClient,
  searchTerm,
  searching,
  searchResults,
  onSearchTermUpdated,
  onSearch,
  onSelectClient,
  onCreateClient,
}: ClientSearchProps) => {
  const { onChange: debouncedInputChanged } = useDebounce(300, onSearch);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    console.log("selectedClient", selectedClient);
  }, [selectedClient]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-full justify-between"
        >
          {selectedClient ? selectedClient.name : "Select client..."}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[--radix-popover-trigger-width] p-0">
        <Command shouldFilter={false}>
          <CommandInput
            placeholder="Search clients..."
            value={searchTerm}
            onValueChange={(value) => {
              onSearchTermUpdated(value);
              // @ts-ignore
              debouncedInputChanged({ target: { value } });
            }}
          />
          <CommandList>
            <CommandGroup>
              <CommandItem
                onSelect={() => {
                  onCreateClient();
                  setOpen(false);
                }}
                className="text-primary"
              >
                <PlusCircle className="mr-2 h-4 w-4" />
                Create new client
              </CommandItem>
            </CommandGroup>
            <CommandSeparator />

            {searching ? (
              <CommandGroup>
                <CommandItem disabled className="text-muted-foreground">
                  Searching...
                </CommandItem>
              </CommandGroup>
            ) : (
              <>
                {searchResults.length > 0 ? (
                  <CommandGroup heading="Existing Clients">
                    {searchResults.map((client) => (
                      <CommandItem
                        key={client.id}
                        onSelect={() => {
                          onSelectClient(client);
                          setOpen(false);
                        }}
                        value={client.name}
                      >
                        <Check
                          className={cn(
                            "mr-2 h-4 w-4",
                            selectedClient?.id === client.id
                              ? "opacity-100"
                              : "opacity-0",
                          )}
                        />
                        {client.name}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                ) : (
                  <CommandEmpty>No clients found.</CommandEmpty>
                )}
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default ClientSearch;
