import React, { useMemo, useState } from "react";
import { filter, find, groupBy, isNil, map, pipe } from "ramda";
import Decimal from "decimal.js-light";

import { Scenario } from "@pricing-tool/lib/scenario/core";

import { toScenarioSummary } from "../../utils/ScenarioSummary";

import PerformanceMetric from "../../../opportunity/fields/PerformanceMetric";

import "./styles.scss";
import { ScenarioComparatorProps } from "./props";
import { ModalFooter } from "@/src/components/common/Modal";
import { Button } from "@/components/ui/button";

const findScenarioById = (id: string, scenarios: Scenario[]) =>
  find((scenario) => scenario.id === id, scenarios);

const asDollar = (value: Decimal | undefined): string =>
  value ? `$${value.toFormat(2)}` : "-";

const asPercent = (value: Decimal | undefined): string =>
  value ? `${value.mul(100).toFormat(2)}%` : "-";

const ScenarioComparator = ({
  scenarios,
  performanceTargets,
  selectedScenarioId,
  comparisonScenarioIds,
  onClose,
}: ScenarioComparatorProps) => {
  const [selectedScenario] = useState(
    selectedScenarioId
      ? findScenarioById(selectedScenarioId, scenarios)
      : undefined,
  );

  const [comparisonScenarios] = useState<Scenario[]>(
    pipe(
      // @ts-ignore - FIXME
      map((scenarioId: string) => findScenarioById(scenarioId, scenarios)),
      filter((x: Scenario | undefined) => !isNil(x)),
    )(comparisonScenarioIds) as Scenario[],
  );

  const scenarioSummaries = useMemo(() => {
    const summaries = map(
      (scenario) => toScenarioSummary(scenario, performanceTargets),
      scenarios,
    );
    return groupBy((summary) => summary.id, summaries);
  }, [scenarios]);

  return (
    <>
      <div className="body">
        <table className="scenarios-comparator-table">
          <colgroup>
            <col className="metric" />
            {selectedScenario && <col className="selected" />}
            {comparisonScenarios.length > 0 &&
              map(
                (scenario) => (
                  <col key={`col-${scenario.id}`} className="comp" />
                ),
                comparisonScenarios,
              )}
          </colgroup>
          <thead>
            <tr>
              <th scope="col">&nbsp;</th>
              {selectedScenario && <th scope="col">{selectedScenario.name}</th>}
              {comparisonScenarios.length > 0 &&
                map(
                  (scenario) => (
                    <th key={`th-${scenario.id}`} scope="col">
                      {scenario.name}
                    </th>
                  ),
                  comparisonScenarios,
                )}
            </tr>
          </thead>
          <tbody>
            <tr className="interestIncome">
              <td>Interest Revenue</td>
              {selectedScenario && (
                <td>
                  {asDollar(
                    selectedScenario.incomeStatement?.feeOrInterestIncome,
                  )}
                </td>
              )}
              {comparisonScenarios.length > 0 && (
                <>
                  {map(
                    (scenario) => (
                      <td key={`td-interestIncome-${scenario.id}`}>
                        {asDollar(
                          scenario.incomeStatement?.feeOrInterestIncome,
                        )}
                      </td>
                    ),
                    comparisonScenarios,
                  )}
                </>
              )}
            </tr>

            <tr className="interestExpense">
              <td>Interest Expense</td>
              {selectedScenario && (
                <td>
                  {asDollar(
                    selectedScenario.incomeStatement?.feeOrInterestExpense,
                  )}
                </td>
              )}
              {comparisonScenarios.length > 0 && (
                <>
                  {map(
                    (scenario) => (
                      <td key={`td-interestExpense-${scenario.id}`}>
                        {asDollar(
                          scenario.incomeStatement?.feeOrInterestExpense,
                        )}
                      </td>
                    ),
                    comparisonScenarios,
                  )}
                </>
              )}
            </tr>

            <tr className="netInterestIncome">
              <td>Net Interest Income</td>
              {selectedScenario && (
                <td>
                  {asDollar(
                    selectedScenario.incomeStatement?.netFeeOrInterestIncome,
                  )}
                </td>
              )}
              {comparisonScenarios.length > 0 && (
                <>
                  {map(
                    (scenario) => (
                      <td key={`td-netInterestIncome-${scenario.id}`}>
                        {asDollar(
                          scenario.incomeStatement?.netFeeOrInterestIncome,
                        )}
                      </td>
                    ),
                    comparisonScenarios,
                  )}
                </>
              )}
            </tr>

            <tr className="nonInterestExpense">
              <td>Non-Interest Expense</td>
              {selectedScenario && (
                <td>
                  {asDollar(
                    selectedScenario.incomeStatement?.nonFeeOrInterestExpense,
                  )}
                </td>
              )}
              {comparisonScenarios.length > 0 && (
                <>
                  {map(
                    (scenario) => (
                      <td key={`td-nonInterestExpense-${scenario.id}`}>
                        {asDollar(
                          scenario.incomeStatement?.nonFeeOrInterestExpense,
                        )}
                      </td>
                    ),
                    comparisonScenarios,
                  )}
                </>
              )}
            </tr>

            <tr className="loanLossReserves">
              <td>Loan Loss Reserves</td>
              {selectedScenario && (
                <td>
                  {asDollar(selectedScenario.incomeStatement?.loanLossReserves)}
                </td>
              )}
              {comparisonScenarios.length > 0 && (
                <>
                  {map(
                    (scenario) => (
                      <td key={`td-loanLossReserves-${scenario.id}`}>
                        {asDollar(scenario.incomeStatement?.loanLossReserves)}
                      </td>
                    ),
                    comparisonScenarios,
                  )}
                </>
              )}
            </tr>

            <tr className="pretaxIncome">
              <td>Pretax Income</td>
              {selectedScenario && (
                <td>
                  {asDollar(selectedScenario.incomeStatement?.pretaxIncome)}
                </td>
              )}
              {comparisonScenarios.length > 0 && (
                <>
                  {map(
                    (scenario) => (
                      <td key={`td-pretaxIncome-${scenario.id}`}>
                        {asDollar(scenario.incomeStatement?.pretaxIncome)}
                      </td>
                    ),
                    comparisonScenarios,
                  )}
                </>
              )}
            </tr>

            <tr className="taxes">
              <td>Taxes</td>
              {selectedScenario && (
                <td>{asDollar(selectedScenario.incomeStatement?.taxes)}</td>
              )}
              {comparisonScenarios.length > 0 && (
                <>
                  {map(
                    (scenario) => (
                      <td key={`td-taxes-${scenario.id}`}>
                        {asDollar(scenario.incomeStatement?.taxes)}
                      </td>
                    ),
                    comparisonScenarios,
                  )}
                </>
              )}
            </tr>

            <tr className="netIncome">
              <td>Net Income</td>
              {selectedScenario && (
                <td>{asDollar(selectedScenario.incomeStatement?.netIncome)}</td>
              )}
              {comparisonScenarios.length > 0 && (
                <>
                  {map(
                    (scenario) => (
                      <td key={`td-netIncome-${scenario.id}`}>
                        {asDollar(scenario.incomeStatement?.netIncome)}
                      </td>
                    ),
                    comparisonScenarios,
                  )}
                </>
              )}
            </tr>

            <tr className="averageBalance">
              <td>Average Balance</td>
              {selectedScenario && (
                <td>
                  {asDollar(selectedScenario.incomeStatement?.averageBalance)}
                </td>
              )}
              {comparisonScenarios.length > 0 && (
                <>
                  {map(
                    (scenario) => (
                      <td key={`td-averageBalance-${scenario.id}`}>
                        {asDollar(scenario.incomeStatement?.averageBalance)}
                      </td>
                    ),
                    comparisonScenarios,
                  )}
                </>
              )}
            </tr>

            <tr className="averageEquity">
              <td>Average Equity</td>
              {selectedScenario && (
                <td>
                  {asDollar(selectedScenario.incomeStatement?.averageEquity)}
                </td>
              )}
              {comparisonScenarios.length > 0 && (
                <>
                  {map(
                    (scenario) => (
                      <td key={`td-averageEquity-${scenario.id}`}>
                        {asDollar(scenario.incomeStatement?.averageEquity)}
                      </td>
                    ),
                    comparisonScenarios,
                  )}
                </>
              )}
            </tr>

            <tr className="roa">
              <td>
                <b>ROA</b>
              </td>
              {selectedScenario && (
                <td>
                  <b>
                    <PerformanceMetric
                      metric={
                        scenarioSummaries[selectedScenario.id][0].metrics
                          .returnOnAssets
                      }
                      performance={
                        scenarioSummaries[selectedScenario.id][0].performance
                          .returnOnAssetsPerformance
                      }
                    />
                  </b>
                </td>
              )}
              {comparisonScenarios.length > 0 && (
                <>
                  {map(
                    (scenario) => (
                      <td key={`td-roa-${scenario.id}`}>
                        <b>
                          <PerformanceMetric
                            metric={
                              scenarioSummaries[scenario.id][0].metrics
                                .returnOnAssets
                            }
                            performance={
                              scenarioSummaries[scenario.id][0].performance
                                .returnOnAssetsPerformance
                            }
                          />
                        </b>
                      </td>
                    ),
                    comparisonScenarios,
                  )}
                </>
              )}
            </tr>

            <tr className="roe">
              <td>
                <b>ROE</b>
              </td>
              {selectedScenario && (
                <td>
                  <b>
                    <PerformanceMetric
                      metric={
                        scenarioSummaries[selectedScenario.id][0].metrics
                          .returnOnEquity
                      }
                      performance={
                        scenarioSummaries[selectedScenario.id][0].performance
                          .returnOnEquityPerformance
                      }
                    />
                  </b>
                </td>
              )}
              {comparisonScenarios.length > 0 && (
                <>
                  {map(
                    (scenario) => (
                      <td key={`td-roe-${scenario.id}`}>
                        <b>
                          <PerformanceMetric
                            metric={
                              scenarioSummaries[scenario.id][0].metrics
                                .returnOnEquity
                            }
                            performance={
                              scenarioSummaries[scenario.id][0].performance
                                .returnOnEquityPerformance
                            }
                          />
                        </b>
                      </td>
                    ),
                    comparisonScenarios,
                  )}
                </>
              )}
            </tr>
          </tbody>
        </table>
      </div>
      <ModalFooter className="flex justify-end">
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </>
  );
};

export default ScenarioComparator;
