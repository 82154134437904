import React, { useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { ApolloError } from "@apollo/client";

import {
  useCreateDocumentUploadUrlMutation,
  useDeleteDocumentMutation,
  useGetDocumentLazyQuery,
  useGetDocumentSummaryQuery,
  useUpdateDocumentMutation,
} from "@pricing-tool/graphql/lib/react";

import LoadingOverlay from "../../../../components/common/LoadingOverlay";
import ErrorBanner from "../../../../components/common/ErrorBanner";
import DocumentForm, {
  DocumentFormValue,
} from "../../../../components/documents/forms/DocumentForm";

function EditDocument() {
  const navigate = useNavigate();
  const { opportunityId, documentId } = useParams();
  const location = useLocation();

  let state = location.state as { backgroundLocation?: Location } | null;
  const isRenderedInModal = !!state?.backgroundLocation;

  const [createDocumentUploadUrl] = useCreateDocumentUploadUrlMutation();
  const [updateDocument] = useUpdateDocumentMutation();
  const [deleteDocument, { loading: deleteLoading, error: deleteError }] =
    useDeleteDocumentMutation();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApolloError>();

  const {
    data: documentSummary,
    loading: loadingDocumentSummary,
    error: errorDocumentSummary,
  } = useGetDocumentSummaryQuery({
    variables: {
      branchId: "branch-1",
      opportunityId: opportunityId!,
      documentId: documentId!,
    },
    fetchPolicy: "network-only",
  });

  const [
    getDocument,
    { data: document, loading: loadingDocument, error: errorDocument },
  ] = useGetDocumentLazyQuery();

  const downloadDocument = useCallback(() => {
    void getDocument({
      variables: {
        branchId: "branch-1",
        opportunityId: opportunityId!,
        documentId: documentId!,
      },
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        const url = data?.getDocument?.downloadSignedUrl;
        if (url) {
          window.open(url, "_blank");
        }
      },
    });
  }, [getDocument, opportunityId, documentId]);

  const uploadDocument = useCallback(
    async (documentFormValue: DocumentFormValue) => {
      let res = await createDocumentUploadUrl({
        variables: {
          branchId: "branch-1",
          input: {
            branchId: "branch-1",
            opportunityId: opportunityId!,
            documentName: documentFormValue.file!.name,
            documentType: documentFormValue.file!.type,
          },
        },
      });
      if (!res.data?.createDocumentUploadUrl) {
        throw new ApolloError({
          errorMessage: "Failed to create document upload url",
        });
      }
      const { preSignedUrl, documentS3Pointer, fields } =
        res.data?.createDocumentUploadUrl;
      const parsedFields: Record<string, string> = JSON.parse(fields);
      const formData = new FormData();
      Object.entries(parsedFields).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append("file", documentFormValue.file!);
      let res1 = await fetch(preSignedUrl, {
        method: "POST",
        body: formData,
      });
      if (!res1.ok) {
        throw new ApolloError({
          errorMessage: "Failed to upload document",
        });
      }
      return [documentFormValue.file?.type, documentS3Pointer] as [
        string | undefined,
        string | undefined,
      ];
    },
    [createDocumentUploadUrl, opportunityId],
  );

  const _deleteDocument = useCallback(() => {
    void deleteDocument({
      variables: {
        branchId: "branch-1",
        opportunityId: opportunityId!,
        documentId: documentSummary!.getDocument!.id,
      },
      update: (cache) => {
        cache.evict({ id: "Document:" + documentSummary!.getDocument!.id });
      },
      onCompleted: () => {
        navigate(-1);
      },
    });
  }, [deleteDocument, opportunityId, documentSummary]);

  const onSubmit = useCallback(
    (documentFormValue: DocumentFormValue) => {
      setLoading(true);
      let getUploadedDocument: Promise<
        [string | undefined, string | undefined]
      >;
      if (documentFormValue.file) {
        getUploadedDocument = uploadDocument(documentFormValue);
      } else {
        getUploadedDocument = Promise.resolve([undefined, undefined]);
      }

      getUploadedDocument
        .then(([documentType, documentS3Pointer]) => {
          return updateDocument({
            variables: {
              branchId: "branch-1",
              documentId: documentId!,
              input: {
                branchId: "branch-1",
                opportunityId: opportunityId!,
                id: documentId!,
                name: documentFormValue.name,
                description: documentFormValue.description,
                documentType,
                documentS3Pointer,
              },
            },
          });
        })
        .then(() => {
          navigate(-1);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [updateDocument, uploadDocument, navigate, opportunityId],
  );

  const onCancel = () => {
    navigate(-1);
  };

  const doc: DocumentFormValue | undefined = useMemo(() => {
    return documentSummary?.getDocument
      ? {
          name: documentSummary.getDocument.name,
          description: documentSummary.getDocument.description || undefined,
        }
      : undefined;
  }, [documentSummary]);

  return (
    <div className="edit-document-page">
      {!isRenderedInModal && <div className="text-2xl p-2">Edit Document</div>}
      <DocumentForm
        document={doc}
        onCancel={onCancel}
        onSubmit={onSubmit}
        onDownloadFile={downloadDocument}
        onDelete={_deleteDocument}
      />

      {loadingDocumentSummary && <LoadingOverlay text="Loading document" />}
      {loadingDocument && <LoadingOverlay text="Downloading document" />}
      {deleteLoading && <LoadingOverlay text="Deleting document" />}
      {loading && <LoadingOverlay text="Updating document" />}

      {error && <ErrorBanner text={error.message} />}
      {errorDocumentSummary && (
        <ErrorBanner text={errorDocumentSummary.message} />
      )}
      {deleteError && <ErrorBanner text={deleteError.message} />}
      {errorDocument && <ErrorBanner text={errorDocument.message} />}
    </div>
  );
}

export default EditDocument;
