import React, { useCallback } from "react";

import {
  OpportunitySummaryFragment,
  useGetOpportunityLazyQuery,
} from "@pricing-tool/graphql/lib/react";
import { Opportunity } from "@pricing-tool/lib/opportunity/core";
import { fromDto, OpportunityDto } from "@pricing-tool/lib/opportunity/dto";

import OpportunitySearchForm from "../../../../opportunity/forms/OpportunitySearchForm";
import ErrorBanner from "../../../../common/ErrorBanner";
import LoadingIndicator from "../../../../common/LoadingIndicator";
import { Button } from "@/components/ui/button";
import { ModalFooter } from "@/src/components/common/Modal";

import "./styles.scss";

export type OpportunityPickerProps = {
  onConfirm: (opportunity: Opportunity) => void;
  onCancel: () => void;
};

const OpportunityPicker = ({ onConfirm, onCancel }: OpportunityPickerProps) => {
  const [getOpportunity, { loading, error }] = useGetOpportunityLazyQuery();

  const onSelectOpportunity = useCallback(
    (opportunity: OpportunitySummaryFragment) => {
      getOpportunity({
        variables: { branchId: "branch-1", opportunityId: opportunity.id },
      }).then(({ data }) =>
        onConfirm(fromDto(data!.getOpportunity! as OpportunityDto)),
      );
    },
    [onConfirm],
  );

  return (
    <div className="component opportunity-picker">
      {!loading && (
        <OpportunitySearchForm
          onSelectOpportunity={onSelectOpportunity}
          onCancel={onCancel}
        />
      )}

      {loading && (
        <>
          <div className="body loader p-4 flex justify-center">
            <LoadingIndicator size="lg" />
          </div>
          <ModalFooter className="flex w-full justify-end">
            <Button variant={"outline"} disabled={true}>
              Cancel
            </Button>
          </ModalFooter>
        </>
      )}
      {error && <ErrorBanner text={error.message} />}
    </div>
  );
};

export default OpportunityPicker;
