import React, { useCallback, useMemo, useState } from "react";
import { filter, find, includes, isEmpty, map } from "ramda";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";

import { Opportunity } from "@pricing-tool/lib/opportunity/core";
import { ProductType } from "@pricing-tool/lib/products/core";

import { DataTable } from "@/components/ui/data-table";
import { Button } from "@/components/ui/button";

import {
  ProductSummary,
  toProductSummary,
} from "../../../utils/ProductSummary";

import "./styles.scss";
import { ModalFooter } from "@/src/components/common/Modal";

export type ProductsPickerProps = {
  opportunity: Opportunity;
  selectMultiple: boolean;
  selectedScenarioId: string;
  onConfirm: (productTypes: ProductType[]) => void;
  onCancel: () => void;
  onBack?: () => void;
};

const ProductsPicker = ({
  opportunity,
  selectMultiple,
  selectedScenarioId,
  onConfirm,
  onBack,
  onCancel,
}: ProductsPickerProps) => {
  const [selectedProductTypes, setSelectedProductTypes] = useState<
    ProductType[]
  >([]);
  const multiSelectAllowed = selectMultiple || false;

  const productSummaries = useMemo(() => {
    if (!selectedScenarioId) return [];

    const selectedScenario = find(
      (scenario) => scenario.id === selectedScenarioId,
      opportunity?.scenarios || [],
    );
    if (!selectedScenario) return [];

    return map(toProductSummary, selectedScenario.products || []);
  }, [opportunity, selectedScenarioId]);

  const checkProduct = useCallback(
    (productType: ProductType) =>
      setSelectedProductTypes([...selectedProductTypes, productType]),
    [selectedProductTypes],
  );

  const uncheckProductType = useCallback(
    (productType: ProductType) =>
      setSelectedProductTypes(
        filter(
          (_productType) => _productType !== productType,
          selectedProductTypes,
        ),
      ),
    [selectedProductTypes],
  );

  const _onConfirm = useCallback(() => {
    if (!selectedScenarioId) return;
    if (isEmpty(selectedProductTypes)) return;

    onConfirm(selectedProductTypes);
  }, [selectedProductTypes, onConfirm, selectedScenarioId]);

  const columnHelper = createColumnHelper<ProductSummary>();

  const multiSelectColumns = multiSelectAllowed
    ? [
        columnHelper.display({
          id: "checkbox",
          cell: (product) => {
            const checked = includes(
              product.row.original.productType,
              selectedProductTypes,
            );
            const onCheckChanged = () =>
              checked
                ? uncheckProductType(product.row.original.productType)
                : checkProduct(product.row.original.productType);
            return (
              <span className="flex align-middle">
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={onCheckChanged}
                />
              </span>
            );
          },
          header: "",
          size: 8,
        }),
      ]
    : [];

  const columns: ColumnDef<any, any>[] = [
    ...multiSelectColumns,
    columnHelper.accessor("name", {
      cell: (product) =>
        multiSelectAllowed ? (
          product.getValue()
        ) : (
          <Button
            onClick={() => onConfirm([product.row.original.productType])}
            variant={"link"}
            size={"inline"}
          >
            {product.getValue()}
          </Button>
        ),
      header: "Product",
    }),
    columnHelper.accessor("keyMetric", {
      cell: (product) => (
        <span>
          {product.row.original.keyMetricName}:{" "}
          {product.row.original.keyMetric && (
            <b>
              {product.row.original.keyMetricUnit === "dollar"
                ? `$${product.row.original.keyMetric.toFormat(0)}`
                : `${product.row.original.keyMetric.mul(100).toFormat(2)}%`}
            </b>
          )}
        </span>
      ),
      header: "Performance",
    }),
  ];

  return (
    <div className="component products-picker">
      <div className="body">
        <>
          {onBack !== undefined && (
            <button onClick={onBack} className="back-button">
              <FontAwesomeIcon icon="chevron-left" /> Back
            </button>
          )}
          {isEmpty(productSummaries) && (
            <div className="empty">No products to display</div>
          )}
          {!isEmpty(productSummaries) && (
            <DataTable columns={columns} data={productSummaries} />
          )}
        </>
      </div>
      <ModalFooter className="flex gap-4 justify-end">
        <Button type="button" variant="outline" onClick={onCancel}>
          Cancel
        </Button>
        {multiSelectAllowed && (
          <Button
            type="button"
            disabled={isEmpty(selectedProductTypes)}
            onClick={_onConfirm}
          >
            Done
          </Button>
        )}
      </ModalFooter>
    </div>
  );
};

export default ProductsPicker;
