import { Schema as S } from "@effect/schema";
import Decimal from "decimal.js-light";

import { ForwardCurves, ForwardCurvesSnapshotSchema } from "./ForwardCurves";
import { IncomeStatementSchema } from "../../../incomeStatement";
import {
  CollateralRecoveryTable,
  CollateralTypeSchema,
} from "./CollateralRecoveryTable";
import { FundingCurve, FundingCurveSnapshotSchema } from "./FundingCurve";
import { DecimalFromString, OptDecimalFromString } from "../../../util/Decimal";
import { IndexRate, IndexRatesSnapshotSchema, IndexSchema } from "./IndexRates";

export type CommercialIndustrialDependencies = {
  startDate: Date;
  latestIndexRates: IndexRate[];
  latestFundingCurve: FundingCurve;
  latestForwardCurves: ForwardCurves;
  taxRate: Decimal;
  minimumLoanLoss: Decimal;
  minimumCapital: Decimal;
  collateralRecoveryTable: CollateralRecoveryTable;
};

const InterestSchema = S.Struct({
  index: S.optional(IndexSchema),
  spread: OptDecimalFromString,
  interestRate: OptDecimalFromString,
});
export type Interest = S.Schema.Type<typeof InterestSchema>;

const PaymentTypeSchema = S.Literal("straight_line", "mortgage_amortization");
export type PaymentType = S.Schema.Type<typeof PaymentTypeSchema>;

const FixedRateTypeSchema = S.Struct({
  _type: S.Literal("fixed"),
});
type FixedRateType = S.Schema.Type<typeof FixedRateTypeSchema>;

const FloatingRateTypeSchema = S.Struct({
  _type: S.Literal("floating"),
});
type FloatingRateType = S.Schema.Type<typeof FloatingRateTypeSchema>;

const AdjustableRateTypeSchema = S.Struct({
  _type: S.Literal("adjustable"),
  fixedPeriod: S.Number,
  adjustmentPeriod: S.Number,
  floor: DecimalFromString,
  cap: DecimalFromString,
});
export type AdjustableRateType = S.Schema.Type<typeof AdjustableRateTypeSchema>;

const RateTypeSchema = S.Union(
  FixedRateTypeSchema,
  FloatingRateTypeSchema,
  AdjustableRateTypeSchema,
);
export type RateType = S.Schema.Type<typeof RateTypeSchema>;

const PaymentFrequencySchema = S.Literal(
  "annual",
  "semi_annual",
  "quarterly",
  "bi_monthly",
  "monthly",
  "semi_monthly",
  "bi_weekly",
  "weekly",
);
export type PaymentFrequency = S.Schema.Type<typeof PaymentFrequencySchema>;

const InterestCalculationMethodSchema = S.Literal(
  "30/360",
  "actual/360",
  "actual/365",
);
export type InterestCalculationMethod = S.Schema.Type<
  typeof InterestCalculationMethodSchema
>;

const PaymentSchema = S.mutable(
  S.Struct({
    date: S.Date,
    daysSinceLastPayment: S.Number,
    interestRate: DecimalFromString,
    beginningBalance: DecimalFromString,
    payment: DecimalFromString,
    interest: DecimalFromString,
    principal: DecimalFromString,
    endingBalance: DecimalFromString,
    balloon: DecimalFromString,
  }),
);
export type Payment = S.Schema.Type<typeof PaymentSchema>;

const AmortizationScheduleSchema = S.mutable(S.Array(PaymentSchema));
export type AmortizationSchedule = S.Schema.Type<
  typeof AmortizationScheduleSchema
>;

const AmortizationSummarySchema = S.Struct({
  effectiveInterestRate: DecimalFromString,
  numberOfPayments: S.Number,
  totalPaid: DecimalFromString,
  interestPaid: DecimalFromString,
  balloonPaid: DecimalFromString,
  averageBalance: DecimalFromString,
  equityOverBalance: OptDecimalFromString,
});
export type AmortizationSummary = S.Schema.Type<
  typeof AmortizationSummarySchema
>;

const PaymentsSchema = S.Struct({
  termYears: S.optional(S.Number),
  amortizationYears: S.optional(S.Number),
  interestCalculationMethod: S.optional(InterestCalculationMethodSchema),
  paymentFrequency: S.optional(PaymentFrequencySchema),
  paymentType: S.optional(PaymentTypeSchema),
  rateType: S.optional(RateTypeSchema),
  amortizationSummary: S.optional(AmortizationSummarySchema),
  amortizationSchedule: S.optional(AmortizationScheduleSchema),
});
export type Payments = S.Schema.Type<typeof PaymentsSchema>;

const CollateralCalculationsSchema = S.Struct({
  combinedLoanToValueRatio: OptDecimalFromString,
  value: OptDecimalFromString,
  priorLiens: OptDecimalFromString,
  effectiveValue: OptDecimalFromString,
  effectiveLoanToValueRatio: OptDecimalFromString,
});
type CollateralCalculations = S.Schema.Type<
  typeof CollateralCalculationsSchema
>;

const CollateralItemMetaSchema = S.Struct({
  collateralType: S.optional(CollateralTypeSchema),
  name: S.optional(S.String),
});

export const CollateralItemSchema = S.extend(
  CollateralItemMetaSchema,
  CollateralCalculationsSchema,
);
export type CollateralItem = S.Schema.Type<typeof CollateralItemSchema>;

const CollateralMetaSchema = S.Struct({
  collateralItems: S.Array(CollateralItemSchema),
  netRecoveryRate: OptDecimalFromString,
});
const CollateralSchema = S.extend(
  CollateralMetaSchema,
  CollateralCalculationsSchema,
);
export type Collateral = S.Schema.Type<typeof CollateralSchema>;

export const CommercialIndustrialSchema = S.Struct({
  _type: S.Literal("ci"),
  loanAmount: OptDecimalFromString,
  indexRatesSnapshot: S.optional(IndexRatesSnapshotSchema),
  fundingCurveSnapshot: S.optional(FundingCurveSnapshotSchema),
  forwardCurvesSnapshot: S.optional(ForwardCurvesSnapshotSchema),
  interest: S.optional(InterestSchema),
  payments: S.optional(PaymentsSchema),
  originationFees: OptDecimalFromString,
  originationExpenses: OptDecimalFromString,
  monthlyServicingCost: OptDecimalFromString,
  collateral: S.optional(CollateralSchema),
  incomeStatement: S.optional(IncomeStatementSchema),
});
export type CommercialIndustrial = S.Schema.Type<
  typeof CommercialIndustrialSchema
>;
