import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useLocation } from "react-router";

import Opportunity from "./routes/opportunities/edit";
import CreateOpportunity from "./routes/opportunities/new";
import Scenario from "./routes/opportunities/edit/scenario";
import Templates from "./routes/settings/templates";
import CreateTemplate from "./routes/settings/templates/new";
import EditTemplate from "./routes/settings/templates/edit";
import ModalPage from "./components/common/ModalPage";
import Documents from "./routes/opportunities/documents";
import CreateDocument from "./routes/opportunities/documents/new";
import EditDocument from "./routes/opportunities/documents/edit";
import CreateClient from "./routes/clients/new";
import EditClient from "./routes/clients/edit";
import NavigationBar from "./components/common/NavigationBar";
import Clients from "./routes/clients";
import Settings from "./routes/settings";
import ClientDetails from "./routes/clients/view";
import Opportunities from "./routes/opportunities";
import HomePage from "./routes";

const Router = () => {
  const location = useLocation();

  const state = location.state as { backgroundLocation?: Location } | null;

  return (
    <>
      <Routes location={state?.backgroundLocation || location}>
        <Route path="/" element={<HomePage />} />
        <Route path="settings">
          <Route path="" element={<Settings />}>
            <Route path="templates">
              <Route path="" element={<Templates />} />
              <Route path="new" element={<CreateTemplate />} />
              <Route path=":templateId" element={<EditTemplate />} />
            </Route>
          </Route>
        </Route>
        <Route path="clients">
          <Route path="" element={<Clients />} />
          <Route path="new" element={<CreateClient />} />
          <Route path=":clientId" element={<ClientDetails />} />
          <Route path=":clientId/edit" element={<EditClient />} />
        </Route>
        <Route path="opportunities">
          <Route path="" element={<Opportunities />} />
          <Route path="new" element={<CreateOpportunity />} />
          <Route path=":opportunityId">
            <Route path="" element={<Opportunity />}>
              <Route path="scenario">
                <Route path=":scenarioId" element={<Scenario />} />
              </Route>
            </Route>
            <Route
              path="documents"
              element={<ModalPage title={"Documents"} width={800} />}
            >
              <Route path="" element={<Documents />} />
              <Route path="new" element={<CreateDocument />} />
              <Route path=":documentId" element={<EditDocument />} />
            </Route>
          </Route>
        </Route>
      </Routes>
      {state?.backgroundLocation && (
        <Routes>
          <Route path="opportunities">
            <Route path=":opportunityId">
              <Route
                path="documents"
                element={
                  <ModalPage
                    title={"Documents"}
                    width={800}
                    contentHeight={500}
                  />
                }
              >
                <Route path="" element={<Documents />} />
                <Route path="new" element={<CreateDocument />} />
                <Route path=":documentId" element={<EditDocument />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      )}
    </>
  );
};

const WrappedRouter = ({ user, signOut }: { user: any; signOut: Function }) => (
  <BrowserRouter>
    <div className="app border-grid flex flex-1 flex-col">
      <NavigationBar user={user} signOut={signOut} />
      <main className="flex flex-1 flex-col">
        <Router />
      </main>
    </div>
  </BrowserRouter>
);

export default WrappedRouter;
