import Decimal from "decimal.js-light";
import { CollateralRecoveryTable } from "../core/CollateralRecoveryTable";

const collateralRecoveryTable: CollateralRecoveryTable = [
  {
    id: "1",
    type: "Commercial Construction",
    nominalRecoveryRate: new Decimal(0.56),
    averageTimeToRecoveryInYears: new Decimal(1.5),
    netRecoveryRate: new Decimal(0.45),
    loanToValueRatio: new Decimal(0.75),
  },
  {
    id: "2",
    type: "CRE: All Types",
    nominalRecoveryRate: new Decimal(0.62),
    averageTimeToRecoveryInYears: new Decimal(1.5),
    netRecoveryRate: new Decimal(0.5),
    loanToValueRatio: new Decimal(0.75),
  },
  {
    id: "3",
    type: "CRE: Government Building",
    nominalRecoveryRate: new Decimal(0.77),
    averageTimeToRecoveryInYears: new Decimal(1.75),
    netRecoveryRate: new Decimal(0.6),
    loanToValueRatio: new Decimal(0.8),
  },
  {
    id: "4",
    type: "CRE: Hospitality",
    nominalRecoveryRate: new Decimal(0.53),
    averageTimeToRecoveryInYears: new Decimal(2),
    netRecoveryRate: new Decimal(0.4),
    loanToValueRatio: new Decimal(0.75),
  },
  {
    id: "5",
    type: "CRE: Industrial/Warehouse",
    nominalRecoveryRate: new Decimal(0.68),
    averageTimeToRecoveryInYears: new Decimal(1.5),
    netRecoveryRate: new Decimal(0.55),
    loanToValueRatio: new Decimal(0.75),
  },
  {
    id: "6",
    type: "CRE: Mixed Use",
    nominalRecoveryRate: new Decimal(0.62),
    averageTimeToRecoveryInYears: new Decimal(1.5),
    netRecoveryRate: new Decimal(0.5),
    loanToValueRatio: new Decimal(0.75),
  },
  {
    id: "7",
    type: "CRE: Multi Family",
    nominalRecoveryRate: new Decimal(0.8),
    averageTimeToRecoveryInYears: new Decimal(1.5),
    netRecoveryRate: new Decimal(0.65),
    loanToValueRatio: new Decimal(0.8),
  },
  {
    id: "8",
    type: "CRE: Office",
    nominalRecoveryRate: new Decimal(0.56),
    averageTimeToRecoveryInYears: new Decimal(1.5),
    netRecoveryRate: new Decimal(0.45),
    loanToValueRatio: new Decimal(0.75),
  },
  {
    id: "9",
    type: "Developed Lots",
    nominalRecoveryRate: new Decimal(0.55),
    averageTimeToRecoveryInYears: new Decimal(1.5),
    netRecoveryRate: new Decimal(0.45),
    loanToValueRatio: new Decimal(0.75),
  },
  {
    id: "10",
    type: "Land",
    nominalRecoveryRate: new Decimal(0.51),
    averageTimeToRecoveryInYears: new Decimal(1.75),
    netRecoveryRate: new Decimal(0.4),
    loanToValueRatio: new Decimal(0.75),
  },
  {
    id: "11",
    type: "Residential Construction: Fully Presold",
    nominalRecoveryRate: new Decimal(0.68),
    averageTimeToRecoveryInYears: new Decimal(1.5),
    netRecoveryRate: new Decimal(0.55),
    loanToValueRatio: new Decimal(0.8),
  },
  {
    id: "12",
    type: "Residential Real Estate: Spec",
    nominalRecoveryRate: new Decimal(0.62),
    averageTimeToRecoveryInYears: new Decimal(1.5),
    netRecoveryRate: new Decimal(0.5),
    loanToValueRatio: new Decimal(0.8),
  },
  {
    id: "13",
    type: "Residential Real Estate: Single Family",
    nominalRecoveryRate: new Decimal(0.68),
    averageTimeToRecoveryInYears: new Decimal(1.5),
    netRecoveryRate: new Decimal(0.55),
    loanToValueRatio: new Decimal(0.8),
  },
  {
    id: "14",
    type: "Accounts Receivable",
    nominalRecoveryRate: new Decimal(0.63),
    averageTimeToRecoveryInYears: new Decimal(1.5),
    netRecoveryRate: new Decimal(0.51),
    loanToValueRatio: new Decimal(0.8),
  },
  {
    id: "15",
    type: "Blanket Lien (AR, Inventory)",
    nominalRecoveryRate: new Decimal(0.43),
    averageTimeToRecoveryInYears: new Decimal(0.5),
    netRecoveryRate: new Decimal(0.4),
    loanToValueRatio: new Decimal(0.8),
  },
  {
    id: "16",
    type: "Cash",
    nominalRecoveryRate: new Decimal(0.98),
    averageTimeToRecoveryInYears: new Decimal(0.25),
    netRecoveryRate: new Decimal(0.95),
    loanToValueRatio: new Decimal(1.0),
  },
  {
    id: "17",
    type: "Commercial Equipment & Agr Non Specialized",
    nominalRecoveryRate: new Decimal(0.35),
    averageTimeToRecoveryInYears: new Decimal(1),
    netRecoveryRate: new Decimal(0.3),
    loanToValueRatio: new Decimal(0.8),
  },
  {
    id: "18",
    type: "Commercial Equipment & Agr Specialized",
    nominalRecoveryRate: new Decimal(0.23),
    averageTimeToRecoveryInYears: new Decimal(1),
    netRecoveryRate: new Decimal(0.2),
    loanToValueRatio: new Decimal(0.8),
  },
  {
    id: "19",
    type: "Commercial Vehicles",
    nominalRecoveryRate: new Decimal(0.35),
    averageTimeToRecoveryInYears: new Decimal(1),
    netRecoveryRate: new Decimal(0.3),
    loanToValueRatio: new Decimal(0.75),
  },
  {
    id: "20",
    type: "Inventory: Commodity",
    nominalRecoveryRate: new Decimal(0.52),
    averageTimeToRecoveryInYears: new Decimal(1),
    netRecoveryRate: new Decimal(0.45),
    loanToValueRatio: new Decimal(0.8),
  },
  {
    id: "21",
    type: "Inventory: Crop",
    nominalRecoveryRate: new Decimal(0.62),
    averageTimeToRecoveryInYears: new Decimal(1.5),
    netRecoveryRate: new Decimal(0.5),
    loanToValueRatio: new Decimal(0.75),
  },
  {
    id: "22",
    type: "Inventory: Non Commodity",
    nominalRecoveryRate: new Decimal(0.29),
    averageTimeToRecoveryInYears: new Decimal(1),
    netRecoveryRate: new Decimal(0.25),
    loanToValueRatio: new Decimal(0.8),
  },
  {
    id: "23",
    type: "Marketable Securities",
    nominalRecoveryRate: new Decimal(0.86),
    averageTimeToRecoveryInYears: new Decimal(0.5),
    netRecoveryRate: new Decimal(0.8),
    loanToValueRatio: new Decimal(0.8),
  },
  {
    id: "24",
    type: "Municipal/Tax Authorities General Obligations",
    nominalRecoveryRate: new Decimal(0.96),
    averageTimeToRecoveryInYears: new Decimal(0.5),
    netRecoveryRate: new Decimal(0.9),
    loanToValueRatio: new Decimal(1.0),
  },
  {
    id: "25",
    type: "Municipal/Tax Authorities Revenue Obligations",
    nominalRecoveryRate: new Decimal(0.94),
    averageTimeToRecoveryInYears: new Decimal(0.75),
    netRecoveryRate: new Decimal(0.85),
    loanToValueRatio: new Decimal(1.0),
  },
  {
    id: "26",
    type: "Tax Revenue",
    nominalRecoveryRate: new Decimal(0.67),
    averageTimeToRecoveryInYears: new Decimal(0.75),
    netRecoveryRate: new Decimal(0.6),
    loanToValueRatio: new Decimal(1.0),
  },
  {
    id: "27",
    type: "Unsecured",
    nominalRecoveryRate: new Decimal(0.2),
    averageTimeToRecoveryInYears: new Decimal(2),
    netRecoveryRate: new Decimal(0.15),
    loanToValueRatio: new Decimal(1.0),
  },
];

export default collateralRecoveryTable;
