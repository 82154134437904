import React, { useCallback, useState } from "react";
import Modal, { ModalFooter } from "../../../common/Modal";
import { filter, find, includes, map } from "ramda";

import "./styles.scss";

import { ScenarioSummary } from "../../utils/ScenarioSummary";
import PerformanceMetric from "../../../opportunity/fields/PerformanceMetric";
import { ScenariosPickerProps } from "./props";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";

const ScenariosPicker = ({
  scenarios,
  selectedScenarioId,
  onConfirm,
  onCancel,
}: ScenariosPickerProps) => {
  const unselectedScenarios = filter(
    (scenario) => scenario.id !== selectedScenarioId,
    scenarios,
  );

  const [selectedScenarioIds, setSelectedScenarioIds] = useState<string[]>([]);
  const [selectedScenario] = useState<ScenarioSummary | undefined>(
    selectedScenarioId
      ? find((scenario) => scenario.id === selectedScenarioId, scenarios || [])
      : undefined,
  );

  const checkScenario = useCallback(
    (scenarioId: string) =>
      setSelectedScenarioIds([...selectedScenarioIds, scenarioId]),
    [selectedScenarioIds],
  );

  const uncheckScenario = useCallback(
    (scenarioId: string) =>
      setSelectedScenarioIds(
        filter(
          (_scenarioId) => _scenarioId !== scenarioId,
          selectedScenarioIds,
        ),
      ),
    [selectedScenarioIds],
  );

  return (
    <Modal
      title={"Compare Scenarios"}
      width={600}
      contentHeight={300}
      onClose={onCancel}
    >
      <div className="body">
        <div className="m-4 rounded-md border">
          <Table className="scenarios-table">
            <TableHeader>
              <TableRow>
                <TableHead scope="col">&nbsp;</TableHead>
                <TableHead scope="col">Scenario</TableHead>
                <TableHead scope="col">ROE</TableHead>
                <TableHead scope="col">ROA</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {selectedScenario && (
                <>
                  <TableRow className="instruction">
                    <TableCell colSpan={4}>Compare...</TableCell>
                  </TableRow>
                  <TableRow key={selectedScenario.id}>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>{selectedScenario.name}</TableCell>
                    <TableCell>
                      <PerformanceMetric
                        metric={selectedScenario.metrics.returnOnEquity}
                        performance={
                          selectedScenario.performance.returnOnEquityPerformance
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <PerformanceMetric
                        metric={selectedScenario.metrics.returnOnAssets}
                        performance={
                          selectedScenario.performance.returnOnAssetsPerformance
                        }
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow className="instruction">
                    <TableCell colSpan={3}>With...</TableCell>
                  </TableRow>
                </>
              )}
              {map((scenario) => {
                const checked = includes(scenario.id, selectedScenarioIds);
                const onCheckChanged = () =>
                  checked
                    ? uncheckScenario(scenario.id)
                    : checkScenario(scenario.id);
                return (
                  <TableRow key={scenario.id} onClick={onCheckChanged}>
                    <TableCell>
                      <input
                        type="checkbox"
                        checked={checked}
                        disabled={false}
                        onChange={onCheckChanged}
                      />
                    </TableCell>
                    <TableCell>{scenario.name}</TableCell>
                    <TableCell>
                      <PerformanceMetric
                        metric={scenario.metrics.returnOnEquity}
                        performance={
                          scenario.performance.returnOnEquityPerformance
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <PerformanceMetric
                        metric={scenario.metrics.returnOnAssets}
                        performance={
                          scenario.performance.returnOnAssetsPerformance
                        }
                      />
                    </TableCell>
                  </TableRow>
                );
              }, unselectedScenarios)}
            </TableBody>
          </Table>
        </div>
      </div>
      <ModalFooter className="flex gap-4 justify-end">
        <Button variant="outline" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          disabled={selectedScenarioIds.length === 0}
          onClick={() => onConfirm(selectedScenarioIds)}
        >
          Done
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ScenariosPicker;
