import React from "react";
import { useForm } from "react-hook-form";
import { Editor } from "@tinymce/tinymce-react";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { ModalFooter } from "@/src/components/common/Modal";
import { Button } from "@/components/ui/button";

import "./styles.scss";

export interface TemplateFormValue {
  name: string;
  description?: string;
  body: string;
}

const defaultTemplateFormValue: TemplateFormValue = {
  name: "",
  description: "",
  body: "",
};

export interface TemplateFormProps {
  template?: TemplateFormValue;
  onCancel: () => void;
  onSubmit: (input: TemplateFormValue) => void;
  onDelete?: () => void;
}

const TemplateForm: React.FC<TemplateFormProps> = (props) => {
  const { template, onCancel, onSubmit, onDelete } = props;

  const form = useForm<TemplateFormValue>({
    defaultValues: template || defaultTemplateFormValue,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = form;

  return (
    <div className="component template-form">
      <Form {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="body">
            <FormField
              control={form.control}
              name="name"
              rules={{ required: "Required" }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Enter a name for the document"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              rules={{ required: "Required" }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <Input
                      type="textarea"
                      placeholder="Description of the document"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="body"
              rules={{ required: "Required" }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Body</FormLabel>
                  <FormControl>
                    <Editor
                      apiKey="nvsti0udg3sdzg0ug3lsba7oou6hy86kzhcc08cscioop2uq"
                      onEditorChange={field.onChange}
                      init={{
                        content_css: ["document"],
                        plugins:
                          "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount pagebreak preview code print",
                        toolbar:
                          "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap pagebreak | removeformat",
                      }}
                      initialValue={template?.body || ""}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <ModalFooter className="flex justify-between">
            <div>
              {props.onDelete && (
                <Button type="button" variant="destructive" onClick={onDelete}>
                  Delete
                </Button>
              )}
            </div>
            <div className="flex gap-4">
              <Button variant="outline" type="button" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </div>
          </ModalFooter>
        </form>
      </Form>
    </div>
  );
};

export default TemplateForm;
