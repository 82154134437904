import React from "react";
import Helmet from "react-helmet";
import { useNavigate } from "react-router";
import { Plus } from "lucide-react";

import {
  TemplateSummaryFragment,
  useGetTemplatesQuery,
} from "@pricing-tool/graphql/lib/react";

import { Button } from "@/components/ui/button";

import TemplatesTable from "../../../components/templates/tables/TemplatesTable";

function Templates() {
  const navigate = useNavigate();

  const { data, loading, error } = useGetTemplatesQuery({
    variables: {
      branchId: "branch-1",
    },
    fetchPolicy: "network-only",
  });

  return (
    <div className="templates px-4">
      <Helmet>
        <title>Templates</title>
      </Helmet>
      <div className="text-2xl mb-4">Templates</div>

      <Button
        variant="outline"
        onClick={() => navigate("/settings/templates/new")}
        className="mb-4"
      >
        <Plus />
        Create a new template
      </Button>

      {loading && <div>Loading...</div>}
      {error && <div>Error: {error.message}</div>}
      {data && data.getTemplates && (
        <TemplatesTable
          templates={data.getTemplates as TemplateSummaryFragment[]}
          onSelect={(template) =>
            navigate(`/settings/templates/${template.id}`)
          }
        />
      )}
    </div>
  );
}

export default Templates;
