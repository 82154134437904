import React, { useCallback, useMemo, useState } from "react";
import { filter, includes, isEmpty, map, pipe } from "ramda";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";

import { Opportunity } from "@pricing-tool/lib/opportunity/core";
import { Scenario } from "@pricing-tool/lib/scenario/core";

import {
  ScenarioSummary,
  toScenarioSummary,
} from "../../../utils/ScenarioSummary";

import { DataTable } from "@/components/ui/data-table";

import PerformanceMetric from "../../../../opportunity/fields/PerformanceMetric";
import { PerformanceTargets } from "../../../../../utils/constructPerformance";

import "./styles.scss";
import { Button } from "@/components/ui/button";
import { ModalFooter } from "@/src/components/common/Modal";

export type ScenariosPickerProps = {
  opportunity: Opportunity;
  performanceTargets?: PerformanceTargets;
  excludedScenarioId?: string;
  selectMultiple: boolean;
  onConfirm: (scenarioIds: string[]) => void;
  onCancel: () => void;
  onBack?: () => void;
};

const ScenariosPicker = ({
  opportunity,
  performanceTargets,
  excludedScenarioId,
  selectMultiple,
  onConfirm,
  onCancel,
  onBack,
}: ScenariosPickerProps) => {
  const [selectedScenarioIds, setSelectedScenarioIds] = useState<string[]>([]);
  const multiSelectAllowed = selectMultiple || false;

  const scenarioSummaries = useMemo(
    () =>
      pipe(
        map((scenario: Scenario) =>
          toScenarioSummary(scenario, performanceTargets),
        ),
        filter((summary: ScenarioSummary) => summary.id !== excludedScenarioId),
      )(opportunity?.scenarios || []),
    [opportunity, excludedScenarioId],
  );

  const checkScenario = useCallback(
    (scenarioId) =>
      setSelectedScenarioIds([...selectedScenarioIds, scenarioId]),
    [selectedScenarioIds],
  );

  const uncheckScenario = useCallback(
    (scenarioId) =>
      setSelectedScenarioIds(
        filter(
          (_scenarioId) => _scenarioId !== scenarioId,
          selectedScenarioIds,
        ),
      ),
    [selectedScenarioIds],
  );

  const _onConfirm = useCallback(() => {
    if (isEmpty(selectedScenarioIds)) return;
    onConfirm(selectedScenarioIds);
  }, [selectedScenarioIds, onConfirm]);

  const columnHelper = createColumnHelper<ScenarioSummary>();

  const multiSelectColumns = multiSelectAllowed
    ? [
        columnHelper.display({
          id: "checkbox",
          cell: (scenario) => {
            const checked = includes(
              scenario.row.original.id,
              selectedScenarioIds,
            );
            const onCheckChanged = () =>
              checked
                ? uncheckScenario(scenario.row.original.id)
                : checkScenario(scenario.row.original.id);
            return (
              <span className="flex align-middle">
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={onCheckChanged}
                />
              </span>
            );
          },
          header: "",
          size: 8,
        }),
      ]
    : [];

  const columns: ColumnDef<ScenarioSummary, any>[] = [
    ...multiSelectColumns,
    columnHelper.accessor("name", {
      cell: (scenario) =>
        multiSelectAllowed ? (
          scenario.getValue()
        ) : (
          <Button
            onClick={() => onConfirm([scenario.row.original.id])}
            variant="link"
            size={"inline"}
          >
            {scenario.getValue()}
          </Button>
        ),
      header: "Scenario",
    }),
    columnHelper.accessor("metrics.returnOnEquity", {
      cell: (scenario) => (
        <PerformanceMetric
          metric={scenario.getValue()}
          performance={
            scenario.row.original.performance.returnOnEquityPerformance
          }
        />
      ),
      header: "ROE",
    }),
    columnHelper.accessor("metrics.returnOnAssets", {
      cell: (scenario) => (
        <PerformanceMetric
          metric={scenario.getValue()}
          performance={
            scenario.row.original.performance.returnOnAssetsPerformance
          }
        />
      ),
      header: "ROA",
    }),
  ];

  return (
    <div className="component scenarios-picker">
      <div className="body">
        <>
          {onBack !== undefined && (
            <button onClick={onBack} className="back-button">
              <FontAwesomeIcon icon="chevron-left" /> Back
            </button>
          )}
          {isEmpty(scenarioSummaries) && (
            <div className="empty">No scenarios to display</div>
          )}
          {!isEmpty(scenarioSummaries) && (
            <DataTable columns={columns} data={scenarioSummaries} />
          )}
        </>
      </div>
      <ModalFooter className="flex gap-4 justify-end">
        <Button variant="outline" onClick={onCancel}>
          Cancel
        </Button>
        {multiSelectAllowed && (
          <Button disabled={isEmpty(selectedScenarioIds)} onClick={_onConfirm}>
            Done
          </Button>
        )}
      </ModalFooter>
    </div>
  );
};

export default ScenariosPicker;
