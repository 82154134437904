import {
  asObservable as asObservableInterest,
  fromInterest,
  InterestRateInput,
  InterestRateOutput,
  trackChanges as trackInterestChanges,
} from "./InterestRateModel";
import {
  AmortizationInput,
  AmortizationOutput,
  asObservable as asObservableAmortization,
  fromPayments,
  trackChanges as trackAmortizationChanges,
} from "./AmortizationModel";
import {
  asObservable as asObservableCollateral,
  CollateralInput,
  CollateralOutput,
  fromCollateral,
  trackChanges as trackCollateralChanges,
} from "./CollateralModel";
import {
  fromIncomeStatement,
  IncomeStatementInput,
} from "./IncomeStatementModel";
import {
  BehaviorSubject,
  combineLatest,
  map,
  Observable,
  of,
  share,
} from "rxjs";
import Decimal from "decimal.js-light";
import {
  CommercialIndustrial,
  CommercialIndustrialDependencies,
} from "../core";
import {
  asObservable as asObservableIncomeStatement,
  ProductOutput,
} from "../../../incomeStatement";

import anyChanged from "../../../util/operators/anyChanged";
import fieldChanged from "../../../util/operators/fieldChanged";
import { FundingCurveSnapshot } from "../core/FundingCurve";
import { IndexRatesSnapshot } from "../core/IndexRates";
import { ForwardCurvesSnapshot } from "../core/ForwardCurves";

export type CommercialIndustrialInput = {
  loanAmount: BehaviorSubject<Decimal | undefined>;
  originationFees: BehaviorSubject<Decimal | undefined>;
  originationExpenses: BehaviorSubject<Decimal | undefined>;
  monthlyServicingCost: BehaviorSubject<Decimal | undefined>;
  indexRatesSnapshot: BehaviorSubject<IndexRatesSnapshot | undefined>;
  fundingCurveSnapshot: BehaviorSubject<FundingCurveSnapshot | undefined>;
  forwardCurvesSnapshot: BehaviorSubject<ForwardCurvesSnapshot | undefined>;
  interestInput: InterestRateInput;
  amortizationInput: AmortizationInput;
  collateralInput: CollateralInput;
  incomeStatementInput: IncomeStatementInput;
};

export type CommercialIndustrialOutput = ProductOutput & {
  interestOutput: InterestRateOutput;
  amortizationOutput: AmortizationOutput;
  collateralOutput: CollateralOutput;
};

export type CommercialIndustrialModel = {
  _type: "ci";
  isDirty: Observable<boolean>;
  input: CommercialIndustrialInput;
  output: CommercialIndustrialOutput;
};

const trackChanges = (
  input: CommercialIndustrialInput,
  output: CommercialIndustrialOutput,
): Observable<boolean> =>
  anyChanged([
    fieldChanged(input.loanAmount),
    fieldChanged(input.originationFees),
    fieldChanged(input.originationExpenses),
    fieldChanged(input.monthlyServicingCost),
    trackInterestChanges(input.interestInput),
    trackAmortizationChanges(input.amortizationInput),
    trackCollateralChanges(input.collateralInput, output.collateralOutput),
  ]);

const defaults: CommercialIndustrial = {
  _type: "ci",
  originationFees: new Decimal(0),
  originationExpenses: new Decimal(0),
  monthlyServicingCost: new Decimal(100),
};

export const init = (
  dependencies: CommercialIndustrialDependencies,
): CommercialIndustrialModel => {
  const model = fromCommercialIndustrial(defaults, dependencies);
  return {
    ...model,
    isDirty: of(true),
  };
};

export const fromCommercialIndustrial = (
  commercialIndustrial: CommercialIndustrial,
  dependencies: CommercialIndustrialDependencies,
): CommercialIndustrialModel => {
  const loanAmount = new BehaviorSubject<Decimal | undefined>(
    commercialIndustrial.loanAmount,
  );
  const originationFees = new BehaviorSubject<Decimal | undefined>(
    commercialIndustrial.originationFees || defaults.originationFees,
  );
  const originationExpenses = new BehaviorSubject<Decimal | undefined>(
    commercialIndustrial.originationExpenses || defaults.originationExpenses,
  );
  const monthlyServicingCost = new BehaviorSubject<Decimal | undefined>(
    commercialIndustrial.monthlyServicingCost || defaults.monthlyServicingCost,
  );

  const defaultIndexRatesSnapshot = {
    indexRates: dependencies.latestIndexRates,
    createdAt: new Date(),
  };
  const defaultFundingCurveSnapshot = {
    fundingCurve: dependencies.latestFundingCurve,
    createdAt: new Date(),
  };
  const defaultForwardCurvesSnapshot = {
    forwardCurves: dependencies.latestForwardCurves,
    createdAt: new Date(),
  };

  const indexRatesSnapshot = new BehaviorSubject<
    IndexRatesSnapshot | undefined
  >(commercialIndustrial.indexRatesSnapshot || defaultIndexRatesSnapshot);

  const fundingCurveSnapshot = new BehaviorSubject<
    FundingCurveSnapshot | undefined
  >(commercialIndustrial.fundingCurveSnapshot || defaultFundingCurveSnapshot);

  const forwardCurvesSnapshot = new BehaviorSubject<
    ForwardCurvesSnapshot | undefined
  >(commercialIndustrial.forwardCurvesSnapshot || defaultForwardCurvesSnapshot);

  const interestRateModel = fromInterest(
    commercialIndustrial.interest,
    indexRatesSnapshot.asObservable(),
    dependencies,
  );
  const amortizationModel = fromPayments(
    commercialIndustrial.payments,
    loanAmount.asObservable(),
    interestRateModel.input.index.asObservable(),
    interestRateModel.output.interestRate,
    interestRateModel.input.spread.asObservable(),
    forwardCurvesSnapshot.asObservable(),
    dependencies,
  );
  const collateralModel = fromCollateral(
    commercialIndustrial.collateral,
    loanAmount.asObservable(),
    dependencies,
  );

  const incomeStatementModel = fromIncomeStatement(
    commercialIndustrial.incomeStatement,
    amortizationModel.output.summary,
    amortizationModel.input.termYears,
    originationFees.asObservable(),
    originationExpenses.asObservable(),
    interestRateModel.input.index,
    monthlyServicingCost.asObservable(),
    fundingCurveSnapshot.asObservable(),
    dependencies,
  );

  const input: CommercialIndustrialInput = {
    loanAmount,
    originationFees,
    originationExpenses,
    monthlyServicingCost,
    indexRatesSnapshot,
    fundingCurveSnapshot,
    forwardCurvesSnapshot,
    interestInput: interestRateModel.input,
    amortizationInput: amortizationModel.input,
    collateralInput: collateralModel.input,
    incomeStatementInput: incomeStatementModel.input,
  };

  const output: CommercialIndustrialOutput = {
    interestOutput: interestRateModel.output,
    amortizationOutput: amortizationModel.output,
    collateralOutput: collateralModel.output,
    incomeStatementOutput: incomeStatementModel.output,
  };

  const isDirty = trackChanges(input, output);

  return {
    _type: "ci",
    isDirty,
    input,
    output,
  };
};

export const asObservable = (
  model: CommercialIndustrialModel,
): Observable<CommercialIndustrial> => {
  const {
    loanAmount,
    indexRatesSnapshot,
    fundingCurveSnapshot,
    forwardCurvesSnapshot,
    interestInput,
    amortizationInput,
    collateralInput,
  } = model.input;
  const {
    interestOutput,
    amortizationOutput,
    collateralOutput,
    incomeStatementOutput,
  } = model.output;

  const interestModel = { input: interestInput, output: interestOutput };
  const amortizationModel = {
    input: amortizationInput,
    output: amortizationOutput,
  };
  const collateralModel = {
    input: collateralInput,
    output: collateralOutput,
  };

  const interest = asObservableInterest(interestModel);
  const amortization = asObservableAmortization(amortizationModel);
  const collateral = asObservableCollateral(collateralModel);
  const incomeStatement = asObservableIncomeStatement(incomeStatementOutput);

  return combineLatest([
    loanAmount,
    indexRatesSnapshot,
    fundingCurveSnapshot,
    forwardCurvesSnapshot,
    interest,
    amortization,
    collateral,
    incomeStatement,
  ]).pipe(
    map(
      ([
        loanAmount,
        indexRatesSnapshot,
        fundingCurveSnapshot,
        forwardCurvesSnapshot,
        interest,
        payments,
        collateral,
        incomeStatement,
      ]) =>
        ({
          _type: "ci",
          loanAmount,
          indexRatesSnapshot,
          fundingCurveSnapshot,
          forwardCurvesSnapshot,
          interest,
          payments,
          collateral,
          incomeStatement,
        } as CommercialIndustrial),
    ),
    share(),
  );
};
