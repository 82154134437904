import React from "react";
import Helmet from "react-helmet";
import { Outlet, useLocation, useNavigate } from "react-router";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";

function SettingsPage() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="page settings p-4 flex flex-row">
      <Helmet>
        <title>Settings</title>
      </Helmet>

      <div>
        <div className="border-r w-52 pr-4">
          <div className="font-semibold mb-2">Settings</div>
          <div className="flex flex-col gap-0.5">
            <Button
              variant={"ghost"}
              className={cn(
                "w-full justify-start",
                location.pathname.startsWith("/settings/templates") &&
                  "bg-gray-200",
              )}
              onClick={() => navigate("/settings/templates")}
            >
              Templates
            </Button>
          </div>
        </div>
      </div>
      <div className="flex-1">
        <Outlet />
      </div>
    </div>
  );
}

export default SettingsPage;
