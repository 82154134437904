import React, { useCallback } from "react";
import Helmet from "react-helmet";
import { useNavigate } from "react-router";
import { ApolloError } from "@apollo/client";

import {
  useCreateTemplateBodyUploadUrlMutation,
  useCreateTemplateMutation,
} from "@pricing-tool/graphql/lib/react";

import LoadingOverlay from "../../../../components/common/LoadingOverlay";
import ErrorBanner from "../../../../components/common/ErrorBanner";
import TemplateForm, {
  TemplateFormValue,
} from "../../../../components/templates/forms/TemplateForm";

function CreateTemplate() {
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<ApolloError | null>(null);

  let [createTemplate] = useCreateTemplateMutation();
  const [createTemplateBodyUploadUrl] =
    useCreateTemplateBodyUploadUrlMutation();

  const onSubmit = useCallback(
    (template: TemplateFormValue) => {
      setLoading(true);
      createTemplateBodyUploadUrl({
        variables: {
          branchId: "branch-1",
          name: template.name,
        },
      })
        .then((res) => {
          if (!res.data?.createTemplateBodyUploadUrl) {
            setError(
              new ApolloError({
                errorMessage: "Failed to create template body upload url",
              }),
            );
            setLoading(false);
            return;
          }

          const { templateId, preSignedUrl, documentS3Pointer, fields } =
            res.data?.createTemplateBodyUploadUrl;

          const parsedFields: Record<string, string> = JSON.parse(fields);
          const formData = new FormData();
          Object.entries(parsedFields).forEach(([key, value]) => {
            formData.append(key, value);
          });
          formData.append("file", template.body);

          fetch(preSignedUrl, {
            method: "POST",
            body: formData,
          })
            .then((res) => {
              if (!res.ok) {
                setError(
                  new ApolloError({
                    errorMessage: "Failed to upload template body",
                  }),
                );
                setLoading(false);
                return;
              }

              createTemplate({
                variables: {
                  branchId: "branch-1",
                  input: {
                    branchId: "branch-1",
                    id: templateId,
                    name: template.name,
                    description: template.description,
                    documentS3Pointer,
                  },
                },
              }).then((createdForm) => {
                if (!createdForm.data?.createTemplate) {
                  setError(
                    new ApolloError({
                      errorMessage: "Failed to create template",
                    }),
                  );
                  setLoading(false);
                  return;
                }

                navigate(`/settings/templates`);
              });
            })
            .catch((err) => {
              console.log(err);
              setError(
                new ApolloError({
                  errorMessage: "Failed to upload template body",
                }),
              );
              setLoading(false);
            });
        })
        .catch((err) => {
          console.log(err);
          setError(
            new ApolloError({
              errorMessage: "Failed to create template body upload url",
            }),
          );
          setLoading(false);
        });
    },
    [createTemplate, navigate],
  );

  const onCancel = () => {
    navigate("/settings/templates");
  };

  return (
    <div className="create-template-page px-4">
      <Helmet>
        <title>Create Template</title>
      </Helmet>
      <div className="text-2xl p-2">Create New Template</div>
      <TemplateForm onCancel={onCancel} onSubmit={onSubmit} />

      {loading && <LoadingOverlay text="Creating template" />}
      {error && <ErrorBanner text={error.message} />}
    </div>
  );
}

export default CreateTemplate;
