import React from "react";
import Decimal from "decimal.js-light";
import { Search, X } from "lucide-react";

import { OpportunitySummaryFragment } from "@pricing-tool/graphql/lib/react";
import { OpportunitySummary } from "@pricing-tool/lib/opportunitySummary/core";

import OpportunitiesTable from "@/src/components/opportunity/tables/OpportunitiesTable";

import LoadingIndicator from "../../../common/LoadingIndicator";
import DebouncedTextField from "../../../common/DebouncedTextField";

import "./styles.scss";
import { Button } from "@/components/ui/button";
import { ModalFooter } from "@/src/components/common/Modal";

// FIXME - don't hardcode
const performanceTargets = {
  returnOnEquity: new Decimal("0.1500"),
  returnOnAssets: new Decimal("0.0100"),
};

export type OpportunitySearchFormProps = {
  searchTerm?: string;
  searching: boolean;
  searchResults: OpportunitySummaryFragment[];
  onSearchTermUpdated: (searchTerm: string) => void;
  onSearch: (searchTerm: string) => void;
  onCancel: () => void;
  onSelectOpportunity: (opportunity: OpportunitySummaryFragment) => void;
};

const OpportunitySearchForm = ({
  searchTerm,
  searching,
  searchResults,
  onSearchTermUpdated,
  onSearch,
  onCancel,
  onSelectOpportunity,
}: OpportunitySearchFormProps) => {
  const searchFieldRef = React.useRef<HTMLInputElement>(null);

  const focusSearchField = () => {
    if (searchFieldRef.current) {
      (searchFieldRef.current as HTMLInputElement).focus();
    }
  };

  React.useEffect(focusSearchField, [searchFieldRef]);

  return (
    <div className="opportunity-search-form">
      <div className="body">
        <div className="header relative my-3 mx-3">
          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
            {searching && <LoadingIndicator size="sm" />}
            {!searching && <Search className="w-4 h-4" />}
          </span>
          <DebouncedTextField
            className="search-input w-full pl-7 py-1"
            debounceTime={300}
            ref={searchFieldRef}
            onChange={(event) => onSearchTermUpdated(event.target.value)}
            onDebouncedChange={onSearch}
            placeholder="Search for opportunities"
            value={searchTerm || ""}
          />
          {searchTerm && searchTerm.length > 0 && (
            <button
              className="absolute inset-y-0 right-0 rounded-l-none btn btn-primary pr-2 py-1"
              onClick={() => {
                onSearchTermUpdated("");
                onSearch("");
              }}
            >
              <X className="w-4 h-4" />
            </button>
          )}
        </div>
        <div className="search-results">
          <div className="mx-3 mb-3">
            <OpportunitiesTable
              variant={"compact"}
              opportunitySummaries={searchResults as OpportunitySummary[]}
              performanceTargets={performanceTargets}
              onSelect={(opp) =>
                onSelectOpportunity(opp as OpportunitySummaryFragment)
              }
            />
          </div>
        </div>
      </div>
      <ModalFooter className="flex w-full justify-end">
        <Button variant="outline" onClick={onCancel}>
          Cancel
        </Button>
      </ModalFooter>
    </div>
  );
};

export default OpportunitySearchForm;
