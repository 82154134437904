import React from "react";
import { find } from "ramda";

import Modal, { ModalFooter } from "../../../../common/Modal";

import ScenarioComparator from "../../ScenarioComparator";
import { OpportunityIncomeStatementModalProps } from "./props";
import { Button } from "@/components/ui/button";

const OpportunityIncomeStatementModal = ({
  opportunity,
  performanceTargets,
  onClose,
}: OpportunityIncomeStatementModalProps) => {
  const scenario = find(
    (scenario) => scenario.id === opportunity.pipelineScenarioId,
    opportunity.scenarios,
  );

  return (
    <Modal
      title="Income Statement"
      width={400}
      contentHeight={scenario ? 505 : 100}
      onClose={onClose}
    >
      {scenario ? (
        <ScenarioComparator
          scenarios={[scenario]}
          performanceTargets={performanceTargets}
          selectedScenarioId={scenario.id}
          comparisonScenarioIds={[]}
          onClose={onClose}
        />
      ) : (
        <>
          <div className="body px-4 py-2">
            There is no pipeline scenario for the opportunity.
          </div>
          <ModalFooter className="flex gap-4 justify-end">
            <Button variant="secondary" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

export default OpportunityIncomeStatementModal;
