import React from "react";

import {
  TemplateSummaryFragment,
  useGetTemplatesQuery,
} from "@pricing-tool/graphql/lib/react";

import ErrorBanner from "../../../../common/ErrorBanner";
import LoadingIndicator from "../../../../common/LoadingIndicator";
import TemplatesTable from "../../../../templates/tables/TemplatesTable";
import { ModalFooter } from "@/src/components/common/Modal";
import { Button } from "@/components/ui/button";

import "./styles.scss";

export type TemplatePickerProps = {
  onConfirm: (template: TemplateSummaryFragment) => void;
  onCancel: () => void;
};

const TemplatePicker = ({ onConfirm, onCancel }: TemplatePickerProps) => {
  const { data, loading, error } = useGetTemplatesQuery({
    variables: { branchId: "branch-1" },
  });

  return (
    <div className="component template-picker">
      {!loading && data?.getTemplates && (
        <>
          <div className="body">
            <h2>Select a template</h2>
            <TemplatesTable
              templates={data.getTemplates as TemplateSummaryFragment[]}
              onSelect={onConfirm}
            />
          </div>
          <ModalFooter className="flex w-full justify-end">
            <Button variant="outline">Cancel</Button>
          </ModalFooter>
        </>
      )}

      {loading && (
        <>
          <div className="body loader">
            <LoadingIndicator size="lg" />
          </div>
          <ModalFooter className="flex w-full justify-end">
            <Button variant="outline" onClick={onCancel}>
              Cancel
            </Button>
          </ModalFooter>
        </>
      )}
      {error && <ErrorBanner text={error.message} />}
    </div>
  );
};

export default TemplatePicker;
