import React from "react";

import Modal from "../../../../common/Modal";
import OpportunityScenarioPickerWorkflow from "../../workflows/OpportunityScenarioPickerWorkflow";

import "./styles.scss";
import { Scenario } from "@pricing-tool/lib/scenario/core";

export type OpportunityScenarioPickerModalProps = {
  onCancel: () => void;
  onConfirm: (scenario: Scenario) => void;
};

const OpportunityScenarioPickerModal = ({
  onCancel,
  onConfirm,
}: OpportunityScenarioPickerModalProps) => {
  return (
    <div className="component opportunity-scenario-picker-modal">
      <Modal
        title="Select Scenarios"
        width={600}
        contentHeight={300}
        onClose={onCancel}
      >
        <OpportunityScenarioPickerWorkflow
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      </Modal>
    </div>
  );
};

export default OpportunityScenarioPickerModal;
