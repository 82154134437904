import { OpportunitySummaryFragment as OpportunitySummaryGraphQl } from "@pricing-tool/graphql/lib/react";

import { OpportunitySummary } from "./core";

import {
  fromDto as fromIncomeStatementDto,
  toDto as toIncomeStatementDto,
} from "../incomeStatement/dto";

import { fromDto as fromClientDto, toDto as toClientDto } from "../client/dto";
import { OpportunityStatus } from "../opportunity/core";

export type OpportunitySummaryDto = OpportunitySummaryGraphQl;

export const fromDto = (dto: OpportunitySummaryDto): OpportunitySummary => {
  return {
    ...dto,
    client: dto.client ? fromClientDto(dto.client) : undefined,
    pricingDate: dto.pricingDate || undefined,
    closeDate: dto.closeDate || undefined,
    owner: dto.owner || undefined,
    status: (dto.status || "IN_PROGRESS") as OpportunityStatus,
    region: dto.region || undefined,
    incomeStatement: dto.incomeStatement
      ? // @ts-ignore - FIXME
        fromIncomeStatementDto(dto.incomeStatement)
      : undefined,
  };
};

export const toDto = (
  opportunitySummary: OpportunitySummary,
): OpportunitySummaryDto => {
  return {
    ...opportunitySummary,
    client: opportunitySummary.client
      ? toClientDto(opportunitySummary.client)
      : undefined,
    incomeStatement: opportunitySummary.incomeStatement
      ? toIncomeStatementDto(opportunitySummary.incomeStatement)
      : undefined,
  };
};
