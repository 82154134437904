import React from "react";
import { sortBy } from "ramda";
import { match } from "ts-pattern";
import moment from "moment/moment";
import { useNavigate } from "react-router";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";

import { OpportunitySummary } from "@pricing-tool/lib/opportunitySummary/core";

import { DataTable } from "@/components/ui/data-table";
import { Button } from "@/components/ui/button";

import {
  constructPerformance,
  PerformanceTargets,
} from "../../../../utils/constructPerformance";

import "./styles.scss";
import PerformanceMetric from "@/src/components/opportunity/fields/PerformanceMetric";

export type OpportunitiesTableProps = {
  variant?: "compact" | "default";
  opportunitySummaries: OpportunitySummary[];
  performanceTargets: PerformanceTargets;
  onSelect?: (opportunity: OpportunitySummary) => void;
};

const OpportunitiesTable = ({
  variant = "default",
  opportunitySummaries,
  performanceTargets,
  onSelect,
}: OpportunitiesTableProps) => {
  const navigate = useNavigate();

  if (!opportunitySummaries || opportunitySummaries.length === 0) {
    return <div className="">No opportunities</div>;
  }

  const sortedOpportunities = sortBy(
    (o) => o?.pricingDate || 1,
    opportunitySummaries,
  );

  const columnHelper = createColumnHelper<OpportunitySummary>();

  const columns: ColumnDef<OpportunitySummary, any>[] = [
    columnHelper.accessor("name", {
      cell: (opportunity) => (
        <Button
          size={"inline"}
          variant={"link"}
          onClick={() =>
            onSelect
              ? onSelect(opportunity.row.original)
              : navigate(`/opportunities/${opportunity.row.original.id}`)
          }
        >
          {opportunity.getValue()}
        </Button>
      ),
      header: "Name",
    }),
    columnHelper.accessor("client.name", {
      cell: (opportunity) => opportunity.getValue(),
      header: "Client",
    }),
    columnHelper.accessor("status", {
      cell: (opportunity) =>
        match(opportunity.getValue())
          .with("IN_PROGRESS", () => "In Progress")
          .with("LOST", () => "Lost")
          .with("WON", () => "Won")
          .otherwise(() => "Unknown"),
      header: "Status",
    }),
    columnHelper.accessor("pricingDate", {
      cell: (opportunity) =>
        opportunity.getValue()
          ? moment(opportunity.getValue()).format("LL")
          : "--",
      header: "Pricing Date",
    }),
    columnHelper.accessor("closeDate", {
      cell: (opportunity) =>
        opportunity.getValue()
          ? moment(opportunity.getValue()).format("LL")
          : "--",
      header: "Close Date",
    }),
  ];

  const returnColumns = [
    columnHelper.accessor("incomeStatement.returnOnEquity", {
      cell: (opportunity) => {
        const performance = constructPerformance(
          opportunity.row.original.incomeStatement?.returnOnEquity,
          opportunity.row.original.incomeStatement?.returnOnAssets,
          performanceTargets,
        );
        return (
          <PerformanceMetric
            metric={opportunity.getValue()}
            performance={performance.returnOnEquityPerformance}
          />
        );
      },
      header: "ROE",
    }),
    columnHelper.accessor("incomeStatement.returnOnAssets", {
      cell: (opportunity) => {
        const performance = constructPerformance(
          opportunity.row.original.incomeStatement?.returnOnEquity,
          opportunity.row.original.incomeStatement?.returnOnAssets,
          performanceTargets,
        );
        return (
          <PerformanceMetric
            metric={opportunity.getValue()}
            performance={performance.returnOnAssetsPerformance}
          />
        );
      },
      header: "ROA",
    }),
  ];

  return (
    <div className="component opportunities-table">
      <DataTable
        columns={
          variant === "default" ? [...columns, ...returnColumns] : columns
        }
        data={sortedOpportunities}
      />
    </div>
  );
};

export default OpportunitiesTable;
