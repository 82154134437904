import React from "react";

import { Product } from "@pricing-tool/lib/products/core";

import Modal from "../../../../common/Modal";
import OpportunityScenarioProductsPickerWorkflow from "../../workflows/OpportunityScenarioProductsPickerWorkflow";

import "./styles.scss";

export type OpportunityScenarioProductsPickerModalProps = {
  onCancel: () => void;
  onConfirm: (scenarioId: string, products: Product[]) => void;
};

const OpportunityScenarioProductsPickerModal = ({
  onCancel,
  onConfirm,
}: OpportunityScenarioProductsPickerModalProps) => {
  return (
    <div className="component opportunity-scenario-products-picker-modal">
      <Modal
        title="Select Products"
        width={600}
        contentHeight={300}
        onClose={onCancel}
      >
        <OpportunityScenarioProductsPickerWorkflow
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      </Modal>
    </div>
  );
};

export default OpportunityScenarioProductsPickerModal;
