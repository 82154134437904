import React from "react";

import Modal, { ModalFooter } from "../../common/Modal";
import { Button } from "@/components/ui/button";
import { File, FileChartColumn, Save, Trash } from "lucide-react";

type OpportunityActionsProps = {
  saveButtonDisabled: boolean;
  onSaveOpportunity: () => void;
  onDeleteOpportunity: () => void;
  onShowIncomeStatement: () => void;
  onShowDocuments: () => void;
};

const Component = (props: OpportunityActionsProps) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    React.useState(false);

  return (
    <>
      <div className="flex justify-between">
        <div className="flex items-center gap-4">
          <Button
            disabled={props.saveButtonDisabled}
            onClick={props.onSaveOpportunity}
          >
            <Save />
            Save
          </Button>
          <Button
            variant="ghost"
            className="text-red-500 hover:text-red-500 hover:border-red-500"
            size="icon"
            onClick={() => setShowDeleteConfirmation(true)}
          >
            <Trash />
          </Button>
        </div>
        <div className="flex items-center gap-4">
          <Button variant="outline" onClick={props.onShowIncomeStatement}>
            <FileChartColumn />
            Income Statement
          </Button>
          <Button variant="outline" onClick={props.onShowDocuments}>
            <File />
            Documents
          </Button>
        </div>
      </div>

      {showDeleteConfirmation && (
        <Modal
          title="Delete Opportunity"
          width={400}
          contentHeight={100}
          onClose={() => setShowDeleteConfirmation(false)}
        >
          <div className="confirm-remove-elements-form">
            <div className="body overflow-auto">
              <div className="m-3">
                <p className="mb-2">
                  Are you sure you want to delete this opportunity? This action
                  cannot be undone.
                </p>
              </div>
            </div>
            <ModalFooter className="flex gap-4 justify-end">
              <Button
                variant="secondary"
                onClick={() => setShowDeleteConfirmation(false)}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setShowDeleteConfirmation(false);
                  props.onDeleteOpportunity();
                }}
              >
                Yes
              </Button>
            </ModalFooter>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Component;
