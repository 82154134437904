import React from "react";
import { includes, prop } from "ramda";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";

import { Element } from "@pricing-tool/lib/products/treasury/core";

import { DataTable } from "@/components/ui/data-table";

import { ElementsTableProps } from "./props";

import "./styles.scss";

const ElementsTable = (props: ElementsTableProps) => {
  if (!props.elements || props.elements.length === 0) {
    return <div className="p-4">No elements to match your search criteria</div>;
  }

  const elementWasPreviouslyAdded = (element: Element) =>
    includes(prop("id", element), props.previouslyAddedElementIds);

  const elementHasBeenAdded = (element: Element) =>
    includes(prop("id", element), props.addedElementIds) ||
    elementWasPreviouslyAdded(element);

  const columnHelper = createColumnHelper<Element>();

  const columns: ColumnDef<Element, any>[] = [
    columnHelper.display({
      id: "checkbox",
      cell: (element) => {
        const checked = elementHasBeenAdded(element.row.original);
        const onCheckChanged = () =>
          checked
            ? props.onUnselect(element.row.original)
            : props.onSelect(element.row.original);
        return (
          <input
            type="checkbox"
            checked={checked}
            disabled={elementWasPreviouslyAdded(element.row.original)}
            onChange={onCheckChanged}
          />
        );
      },
      header: "",
    }),
    columnHelper.accessor("id", {
      cell: (element) => <span className="text-xs">{element.getValue()}</span>,
      header: "Element ID",
    }),
    columnHelper.accessor("name", {
      cell: (element) => <span className="text-xs">{element.getValue()}</span>,
      header: "Name",
    }),
    columnHelper.accessor("productFamily", {
      cell: (element) => <span className="text-xs">{element.getValue()}</span>,
      header: "Product Family",
    }),
    columnHelper.accessor("afpCode", {
      cell: (element) => <span className="text-xs">{element.getValue()}</span>,
      header: "AFP Code",
    }),
  ];

  const mutableElements = [...props.elements];

  return (
    <div className="component elements-table">
      <DataTable columns={columns} data={mutableElements} />
    </div>
  );
};

export default ElementsTable;
