import Modal from "../../../common/Modal";
import { includes, map } from "ramda";
import { match } from "ts-pattern";

import { products, ProductType } from "@pricing-tool/lib/products/core";

import "./styles.scss";
import { Button } from "@/components/ui/button";

export type ProductPickerProps = {
  addedProducts: ProductType[];
  onSelect: (product: ProductType) => void;
  onCancel: () => void;
  onCloneFromThisOpportunityClicked: () => void;
  onCloneFromOtherOpportunityClicked: () => void;
};

const ProductPicker = ({
  addedProducts,
  onSelect,
  onCancel,
  onCloneFromThisOpportunityClicked,
  onCloneFromOtherOpportunityClicked,
}: ProductPickerProps) => {
  return (
    <Modal
      title="Add Product"
      width={600}
      contentHeight={400}
      onClose={onCancel}
    >
      <div className="component product-picker">
        <div className="products">
          {map(
            (product) => (
              <button
                key={product}
                className={`product ${
                  includes(product, addedProducts) && "disabled"
                }`}
                disabled={includes(product, addedProducts)}
                onClick={() => onSelect(product)}
              >
                {match(product)
                  .with("treasury", () => "Treasury")
                  .with("ci", () => "Commercial & Industrial Loan")
                  .with("deposits", () => "Deposits")
                  .with("cre", () => "Commercial Real Estate Loan")
                  .exhaustive()}
              </button>
            ),
            products,
          )}
        </div>
        <div className="clone-prompt">
          ...or,{" "}
          <Button
            variant="link"
            size="inline"
            onClick={onCloneFromThisOpportunityClicked}
          >
            clone from another scenario in this opportunity
          </Button>
          .
        </div>
        <div className="clone-prompt">
          ...or,{" "}
          <Button
            variant="link"
            size="inline"
            onClick={onCloneFromOtherOpportunityClicked}
          >
            clone from another opportunity
          </Button>
          .
        </div>
      </div>
    </Modal>
  );
};

export default ProductPicker;
