import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  RenderedTemplate,
  useCreateRenderedTemplateMutation,
} from "@pricing-tool/graphql/lib/react";

import ErrorBanner from "../../../../common/ErrorBanner";
import LoadingIndicator from "../../../../common/LoadingIndicator";
import { ModalFooter } from "@/src/components/common/Modal";
import { Button } from "@/components/ui/button";

import "./styles.scss";

export type CreateRenderedTemplateProps = {
  templateId: string;
  opportunityId: string;
  scenarioId: string;
  onBack?: () => void;
  onCancel: () => void;
  onComplete: (renderedTemplate: RenderedTemplate) => void;
};

const CreateRenderedTemplate = ({
  templateId,
  opportunityId,
  scenarioId,
  onBack,
  onCancel,
  onComplete,
}: CreateRenderedTemplateProps) => {
  const [createRenderedTemplate, { loading, error }] =
    useCreateRenderedTemplateMutation({
      variables: {
        branchId: "branch-1",
        templateId,
        opportunityId,
        scenarioId,
      },
    });

  useEffect(() => {
    createRenderedTemplate().then((result) => {
      if (result.data) {
        onComplete(result.data.createRenderedTemplate as RenderedTemplate);
      }
    });
  }, []);

  return (
    <div className="component create-rendered-template">
      <div className="body">
        {onBack !== undefined && (
          <button onClick={onBack} className="back-button">
            <FontAwesomeIcon icon="chevron-left" /> Back
          </button>
        )}
        <div className="flex flex-col justify-center items-center">
          <div className="loader">
            <LoadingIndicator size="lg" />
          </div>
          <h2 className="text-center font-semibold mt-4">
            Generating document...
          </h2>
        </div>
        {error && <ErrorBanner text={error.message} />}
      </div>
      <ModalFooter className="flex w-full justify-end">
        <Button variant="outline" onClick={onCancel}>
          Cancel
        </Button>
      </ModalFooter>
    </div>
  );
};

export default CreateRenderedTemplate;
