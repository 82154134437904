import React, { useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router";

import {
  DocumentSummaryFragment,
  useDeleteDocumentMutation,
  useGetDocumentLazyQuery,
  useGetDocumentsQuery,
} from "@pricing-tool/graphql/lib/react";

import DocumentsTable from "../../../components/documents/tables/DocumentsTable";
import LoadingOverlay from "../../../components/common/LoadingOverlay";
import { ModalFooter } from "@/src/components/common/Modal";
import { Button } from "@/components/ui/button";

function Documents() {
  const navigate = useNavigate();
  const { opportunityId } = useParams();
  const location = useLocation();

  let state = location.state as { backgroundLocation?: Location } | null;
  let renderedInModal = !!state?.backgroundLocation;

  const { data, loading, error } = useGetDocumentsQuery({
    variables: {
      branchId: "branch-1",
      opportunityId: opportunityId!,
    },
    fetchPolicy: "network-only",
  });

  const [getDocument, { loading: documentLoading, error: documentError }] =
    useGetDocumentLazyQuery();

  const [deleteDocument, { loading: deleteLoading, error: deleteError }] =
    useDeleteDocumentMutation();

  const downloadDocument = useCallback(
    (document: DocumentSummaryFragment) => {
      void getDocument({
        variables: {
          branchId: "branch-1",
          opportunityId: opportunityId!,
          documentId: document.id,
        },
        fetchPolicy: "network-only",
        onCompleted: (data) => {
          const url = data?.getDocument?.downloadSignedUrl;
          if (url) {
            window.open(url, "_blank");
          }
        },
      });
    },
    [getDocument, opportunityId],
  );

  const _deleteDocument = useCallback(
    (document: DocumentSummaryFragment) => {
      void deleteDocument({
        variables: {
          branchId: "branch-1",
          opportunityId: opportunityId!,
          documentId: document.id,
        },
        update: (cache) => {
          cache.evict({ id: "Document:" + document.id });
        },
      });
    },
    [deleteDocument, opportunityId],
  );

  return (
    <div className="documents">
      <div className="body overflow-auto p-4">
        {!renderedInModal && <div className="text-2xl mb-4">Documents</div>}

        <Button
          variant="outline"
          onClick={() => navigate("new", { state })}
          className="my-3"
        >
          Upload document
        </Button>

        {loading && <div>Loading...</div>}
        {documentLoading && <LoadingOverlay text="Fetching document" />}
        {deleteLoading && <LoadingOverlay text="Deleting document" />}

        {error && <div>Error: {error.message}</div>}
        {documentError && <div>Error: {documentError.message}</div>}
        {deleteError && <div>Error: {deleteError.message}</div>}

        {data && data.getDocuments && (
          <DocumentsTable
            documents={data.getDocuments as DocumentSummaryFragment[]}
            onDownload={downloadDocument}
            onDelete={_deleteDocument}
          />
        )}
      </div>
      {renderedInModal && (
        <ModalFooter className="flex gap-4 justify-end">
          <Button
            variant="secondary"
            onClick={() => navigate(state?.backgroundLocation!)}
          >
            Close
          </Button>
        </ModalFooter>
      )}
    </div>
  );
}

export default Documents;
