import React, { useCallback, useEffect, useState } from "react";
import { find } from "ramda";

import { Opportunity } from "@pricing-tool/lib/opportunity/core";
import { Scenario } from "@pricing-tool/lib/scenario/core";

import OpportunityPicker from "../../fields/OpportunityPicker";
import ScenariosPicker from "../../fields/ScenariosPicker";

import "./styles.scss";

export type OpportunityScenarioPickerWorkflowProps = {
  onConfirm: (scenario: Scenario) => void;
  onCancel: () => void;
};

const OpportunityScenarioPickerWorkflow = ({
  onConfirm,
  onCancel,
}: OpportunityScenarioPickerWorkflowProps) => {
  const [workflowState, setWorkflowState] = useState<
    "opportunity" | "scenario"
  >("opportunity");
  const [selectedOpportunity, setSelectedOpportunity] = useState<
    Opportunity | undefined
  >();

  useEffect(() => {
    if (!selectedOpportunity) {
      setWorkflowState("opportunity");
      return;
    }

    setWorkflowState("scenario");
  }, [selectedOpportunity]);

  const _onConfirm = useCallback(
    ([selectedScenarioId, ...rest]: string[]) => {
      if (!selectedOpportunity) return;
      if (!selectedScenarioId) return;

      const selectedScenario = find(
        (scenario) => scenario.id === selectedScenarioId,
        selectedOpportunity.scenarios || [],
      );

      onConfirm(selectedScenario!);
    },
    [selectedOpportunity, onConfirm],
  );

  return (
    <div className="component opportunity-scenario-picker-workflow">
      {workflowState === "opportunity" && (
        <OpportunityPicker
          onConfirm={setSelectedOpportunity}
          onCancel={onCancel}
        />
      )}

      {workflowState === "scenario" && (
        <>
          <ScenariosPicker
            opportunity={selectedOpportunity!}
            selectMultiple={false}
            onConfirm={_onConfirm}
            onBack={() => setSelectedOpportunity(undefined)}
            onCancel={onCancel}
          />
        </>
      )}
    </div>
  );
};

export default OpportunityScenarioPickerWorkflow;
