import React from "react";
import { map } from "ramda";

import Scenario from "../Scenario";
import { ScenariosProps } from "./props";
import { PlusCircle } from "lucide-react";
import { Button } from "@/components/ui/button";

const Scenarios = ({
  opportunityId,
  scenarios,
  pipelineScenarioId,
  onAddScenario,
  onSelectScenario,
  onRemoveScenario,
  onRenameScenario,
  onDuplicateScenario,
  onCompareScenarioWith,
  onMakePipelineScenario,
}: ScenariosProps) => {
  return (
    <div className="flex flex-col py-2 px-4 bg-gray-100">
      <div className="uppercase text-gray-400 text-xs font-semibold">
        Scenarios
      </div>
      <div className="flex flex-1 h-24 items-center py-2">
        {map(
          (scenario) => (
            <div key={scenario.name} className="pr-4">
              <Scenario
                opportunityId={opportunityId}
                scenario={scenario}
                onSelect={() => onSelectScenario(scenario.id)}
                onRemove={() => onRemoveScenario(scenario.id)}
                onRename={(name: string) => onRenameScenario(scenario.id, name)}
                onDuplicate={(name: string) =>
                  onDuplicateScenario(scenario.id, name)
                }
                onCompareWith={
                  scenarios.length > 1
                    ? () => onCompareScenarioWith(scenario.id)
                    : undefined
                }
                onMakePipelineScenario={
                  scenario.id === pipelineScenarioId
                    ? undefined
                    : () => onMakePipelineScenario(scenario.id)
                }
              />
            </div>
          ),
          scenarios,
        )}
        <Button
          onClick={onAddScenario}
          variant="outline"
          className="h-full border-dashed border-2 rounded-xl text-gray-400 hover:text-gray-600"
        >
          <div className="flex gap-1 items-center">
            <PlusCircle />
            Add Scenario
          </div>
        </Button>
      </div>
    </div>
  );
};

export default Scenarios;
