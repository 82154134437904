import React, { useCallback, useRef, useState } from "react";

import Modal, { ModalFooter } from "../Modal";

import "./styles.scss";
import { Button } from "@/components/ui/button";

export type NewScenarioModalProps = {
  onClose: () => void;
  onConfirm: (name: string) => void;
  onCloneScenarioClicked: () => void;
};

const NewScenarioModal = ({
  onClose,
  onConfirm,
  onCloneScenarioClicked,
}: NewScenarioModalProps) => {
  const [name, setName] = useState("");

  const nameFieldRef = useRef<HTMLInputElement>(null);
  const focusNameField = () => {
    if (nameFieldRef.current) {
      (nameFieldRef.current as HTMLInputElement).focus();
    }
  };

  React.useEffect(focusNameField, [nameFieldRef]);

  const _onConfirm = useCallback(() => {
    if (name === "") {
      // TODO - visual indicator of issue with name
    } else {
      onConfirm(name);
    }
  }, [onConfirm, name]);

  return (
    <Modal
      title="Add Scenario"
      width={400}
      contentHeight={400}
      onClose={onClose}
    >
      <div className="component add-scenario">
        <div className="relative m-3">
          <input
            className="search-input w-full pl-1 py-1 border rounded-md bg-gray-100 text-sm"
            type="text"
            ref={nameFieldRef}
            onChange={(field) => setName(field.target.value)}
            placeholder="Scenario name"
            value={name}
          />
          <div className="clone-prompt">
            ...or,{" "}
            <Button
              variant="link"
              size="inline"
              onClick={onCloneScenarioClicked}
            >
              clone a scenario from another opportunity
            </Button>
            .
          </div>
        </div>
        <ModalFooter className="flex gap-4 justify-end">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={_onConfirm}>Done</Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

export default NewScenarioModal;
