import React from "react";
import { Settings } from "lucide-react";
import { useNavigate } from "react-router";

import { Button } from "@/components/ui/button";

import UserMenu from "@/src/components/common/NavigationBar/UserMenu";
import { MainNav } from "@/src/components/common/NavigationBar/MainNav";

import "./styles.scss";

export type NavigationBarProps = {
  user: any;
  signOut: Function;
};

const NavigationBar = ({ user, signOut }: NavigationBarProps) => {
  const navigate = useNavigate();
  return (
    <header className="component navigation-bar">
      <MainNav />
      <div className="content-group">
        <Button
          variant="ghost"
          className="relative h-8 w-8 rounded-full"
          onClick={() => navigate("/settings")}
        >
          <Settings className="h-8 w-8" />
        </Button>
        <UserMenu user={user} signOut={signOut} />
      </div>
    </header>
  );
};

export default NavigationBar;
