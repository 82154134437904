import Decimal from "decimal.js-light";
import moment from "moment";
import { Schema as S } from "@effect/schema";
import { DecimalFromString } from "../../../util/Decimal";

export const FundingCurveSchema = S.Struct({
  date: S.Date,
  curve: S.Array(
    S.Struct({
      month: S.String,
      rate: DecimalFromString,
    }),
  ),
});
export type FundingCurve = S.Schema.Type<typeof FundingCurveSchema>;

export const FundingCurveSnapshotSchema = S.Struct({
  fundingCurve: FundingCurveSchema,
  createdAt: S.Date,
});
export type FundingCurveSnapshot = S.Schema.Type<
  typeof FundingCurveSnapshotSchema
>;

export const getRateAtDate = (
  fundingCurve: FundingCurve,
  date: Date,
): Decimal => {
  const curveDate = fundingCurve.date;
  const monthDifference = moment(date).diff(moment(curveDate), "months");

  if (monthDifference < 1) {
    return fundingCurve.curve[0].rate;
  }

  const rate = fundingCurve.curve.find(
    (row) => row.month === `${monthDifference}`,
  );

  return rate
    ? rate.rate
    : fundingCurve.curve[fundingCurve.curve.length - 1].rate;
};
