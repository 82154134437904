import React from "react";
import { sortBy } from "ramda";
import moment from "moment";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";

import { TemplateSummaryFragment } from "@pricing-tool/graphql/lib/react";

import { DataTable } from "@/components/ui/data-table";
import { Button } from "@/components/ui/button";

import "./styles.scss";

export type TemplatesTableProps = {
  templates: TemplateSummaryFragment[];
  onSelect: (template: TemplateSummaryFragment) => void;
};

const TemplatesTable = ({ templates, onSelect }: TemplatesTableProps) => {
  if (!templates || templates.length === 0) {
    return <div className="p-4">No templates</div>;
  }

  const sortedTemplates = sortBy((t) => t?.name || 1, templates);

  const columnHelper = createColumnHelper<TemplateSummaryFragment>();

  const columns: ColumnDef<TemplateSummaryFragment, any>[] = [
    columnHelper.accessor("name", {
      cell: (template) => (
        <Button
          variant="link"
          size={"inline"}
          onClick={() => onSelect(template.row.original)}
        >
          {template.getValue()}
        </Button>
      ),
      header: "Name",
    }),
    columnHelper.accessor("description", {
      cell: (template) => template.getValue(),
      header: "Description",
    }),
    columnHelper.accessor("updatedAt", {
      cell: (template) => moment(template.getValue()).format("LLL"),
      header: "Last Updated At",
    }),
    columnHelper.accessor("updatedBy", {
      cell: (template) => template.getValue(),
      header: "Last Updated By",
    }),
  ];

  return (
    <div className="component templates-table">
      <DataTable columns={columns} data={sortedTemplates} />
    </div>
  );
};

export default TemplatesTable;
