import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";

import { Opportunity } from "@pricing-tool/lib/opportunity/core";
import { Client } from "@pricing-tool/graphql/lib/react";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";

import { cn } from "@/lib/utils";

import ClientSearch from "../../../client/fields/ClientSearch";

import "./styles.scss";
import { ModalFooter } from "@/src/components/common/Modal";

export type OpportunityFormProps = {
  opportunity?: Opportunity;
  client?: Client;
  submitButtonLabel?: string;
  onCancel: () => void;
  onSubmit: (data: Opportunity) => void;
  onCreateClient: () => void;
};

const OpportunityForm = (props: OpportunityFormProps) => {
  const form = useForm<Opportunity>({
    defaultValues: props.opportunity || {
      name: "",
      client: undefined,
      status: "IN_PROGRESS",
      pricingDate: moment().format("YYYY-MM-DD"),
      closeDate: undefined,
    },
  });
  const { setValue, watch } = form;

  const pricingDate = watch("pricingDate");
  const closeDate = watch("closeDate");
  const client = watch("client");

  const pricingDateValue = useMemo(
    () => (pricingDate ? new Date(pricingDate) : undefined),
    [pricingDate],
  );
  const closeDateValue = useMemo(
    () => (closeDate ? new Date(closeDate) : undefined),
    [closeDate],
  );

  useEffect(() => {
    if (!props.client) return;
    setValue("client", props.client);
  }, [setValue, props.client]);

  return (
    <div className="component opportunity-form">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(props.onSubmit)}>
          <div className="body">
            <FormField
              control={form.control}
              name="name"
              rules={{ required: "Required" }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input placeholder="Enter opportunity name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="clientId"
              rules={{ required: "Required" }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Client</FormLabel>
                  <FormControl>
                    <ClientSearch
                      value={client}
                      onChange={(selectedClient) => {
                        field.onChange(selectedClient.id);
                        setValue("client", selectedClient);
                      }}
                      onCreateClient={props.onCreateClient}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="status"
              rules={{ required: "Required" }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Status</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select status" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="IN_PROGRESS">In Progress</SelectItem>
                      <SelectItem value="WON">Closed Won</SelectItem>
                      <SelectItem value="LOST">Closed Lost</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="pricingDate"
              rules={{ required: "Required" }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Pricing Date</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          className={cn(
                            "w-full pl-3 text-left font-normal",
                            !field.value && "text-muted-foreground",
                          )}
                        >
                          {pricingDateValue ? (
                            format(pricingDateValue, "PPP")
                          ) : (
                            <span>Pick a date</span>
                          )}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        selected={pricingDateValue}
                        onSelect={(value) => {
                          field.onChange(
                            value ? format(value, "yyyy-MM-dd") : undefined,
                          );
                        }}
                        disabled={(date) =>
                          date < new Date(new Date().setHours(0, 0, 0, 0))
                        }
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="closeDate"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Close Date</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          className={cn(
                            "w-full pl-3 text-left font-normal",
                            !field.value && "text-muted-foreground",
                          )}
                        >
                          {closeDateValue ? (
                            format(closeDateValue, "PPP")
                          ) : (
                            <span>Pick a date</span>
                          )}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        selected={closeDateValue}
                        onSelect={(value) => {
                          field.onChange(
                            value ? format(value, "yyyy-MM-dd") : undefined,
                          );
                        }}
                        disabled={(date) =>
                          date < new Date(new Date().setHours(0, 0, 0, 0))
                        }
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <ModalFooter className="flex gap-4 justify-end">
            <Button type="button" variant="outline" onClick={props.onCancel}>
              Cancel
            </Button>
            <Button type="submit">{props.submitButtonLabel || "Done"}</Button>
          </ModalFooter>
        </form>
      </Form>
    </div>
  );
};

export default OpportunityForm;
