import React, { useCallback, useEffect, useState } from "react";
import Helmet from "react-helmet";
import Decimal from "decimal.js-light";
import { Plus } from "lucide-react";
import { useNavigate } from "react-router";

import {
  useGetOpportunitiesLazyQuery,
  useSearchOpportunitiesByNameLazyQuery,
} from "@pricing-tool/graphql/lib/react";

import { OpportunitySummary } from "@pricing-tool/lib/opportunitySummary/core";
import { fromDto } from "@pricing-tool/lib/opportunitySummary/dto";

import useDebounce from "../../hooks/useDebounce";

import OpportunitiesTable from "../../components/opportunity/tables/OpportunitiesTable";
import { Button } from "@/components/ui/button";

// FIXME - don't hardcode
const performanceTargets = {
  returnOnEquity: new Decimal("0.1500"),
  returnOnAssets: new Decimal("0.0100"),
};

function OpportunitiesPage() {
  const navigate = useNavigate();

  const [displayResults, setDisplayResults] = useState<
    OpportunitySummary[] | undefined
  >(undefined);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchOpportunities, { loading: searching, error: searchError }] =
    useSearchOpportunitiesByNameLazyQuery();

  const onSearch = useCallback(
    (searchTerm: string) => {
      if (!searchTerm || searchTerm === "") {
        void getOpportunities();
        return;
      }

      void searchOpportunities({
        variables: {
          branchId: "branch-1",
          searchTerm: searchTerm,
        },
        onCompleted: (data) => {
          setDisplayResults(data.searchOpportunitiesByName.items.map(fromDto));
        },
      });
    },
    [searchOpportunities],
  );

  const { onChange: debouncedInputChange } = useDebounce(300, onSearch);

  const [getOpportunities, { loading, error }] = useGetOpportunitiesLazyQuery({
    variables: {
      branchId: "branch-1",
      paginationOptions: {
        limit: 100,
      },
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setDisplayResults(data.getOpportunities.items.map(fromDto));
    },
  });

  const inputRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    void getOpportunities();
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  return (
    <div className="page opportunities">
      <Helmet>
        <title>Opportunities</title>
      </Helmet>
      <div className="text-2xl mb-4">Opportunities</div>

      <Button variant="outline" onClick={() => navigate("/opportunities/new")}>
        <Plus />
        Create a new opportunity
      </Button>

      <div className="flex flex-row items-center py-3 gap-3">
        <span>Search for opportunities by name: </span>
        <input
          ref={inputRef}
          className="focus:ring-2 focus:ring-blue-500 ring-2 ring-blue-400 border border-gray-30                                                                                                                                                                                           0 rounded-md px-2"
          value={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value);
            debouncedInputChange(event);
          }}
        />
        {loading && <div>Loading...</div>}
        {searching && <div>Loading...</div>}
      </div>

      {error && <div>Error: {error.message}</div>}
      {searchError && <div>Error: {searchError.message}</div>}

      {displayResults && (
        <OpportunitiesTable
          opportunitySummaries={displayResults}
          performanceTargets={performanceTargets}
        />
      )}
    </div>
  );
}

export default OpportunitiesPage;
