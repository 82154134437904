import React, { useCallback } from "react";
import Helmet from "react-helmet";
import { useNavigate, useParams } from "react-router";

import {
  useCreateTemplateBodyUploadUrlMutation,
  useDeleteTemplateMutation,
  useGetTemplateQuery,
  useUpdateTemplateMutation,
} from "@pricing-tool/graphql/lib/react";

import LoadingOverlay from "../../../../components/common/LoadingOverlay";
import ErrorBanner from "../../../../components/common/ErrorBanner";
import TemplateForm, {
  TemplateFormValue,
} from "../../../../components/templates/forms/TemplateForm";
import { ApolloError } from "@apollo/client";

function EditTemplate() {
  const { templateId } = useParams();
  const navigate = useNavigate();

  const [body, setBody] = React.useState<string | undefined>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<Error | undefined>();

  const [updateLoading, setUpdateLoading] = React.useState<boolean>(false);
  const [updateError, setUpdateError] = React.useState<Error | undefined>();

  const { data } = useGetTemplateQuery({
    variables: {
      branchId: "branch-1",
      templateId: templateId!,
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const bodySignedUrl = data.getTemplate?.bodySignedUrl;
      if (bodySignedUrl) {
        fetch(bodySignedUrl, {})
          .then((res) => res.text())
          .then((body) => setBody(body))
          .catch((err) => setError(err))
          .finally(() => setLoading(false));
      }
    },
    onError: (error) => {
      setLoading(false);
      setError(error);
    },
  });

  const [createTemplateBodyUploadUrl] =
    useCreateTemplateBodyUploadUrlMutation();
  const [updateTemplate] = useUpdateTemplateMutation();

  const [deleteTemplate, { loading: deleteLoading, error: deleteError }] =
    useDeleteTemplateMutation();

  const onSubmit = useCallback(
    (template: TemplateFormValue) => {
      setUpdateLoading(true);
      createTemplateBodyUploadUrl({
        variables: {
          branchId: "branch-1",
          templateId: templateId!,
          name: template.name,
        },
      })
        .then((res) => {
          if (!res.data?.createTemplateBodyUploadUrl) {
            setUpdateError(
              new ApolloError({
                errorMessage: "Failed to create template body upload url",
              }),
            );
            setUpdateLoading(false);
            return;
          }

          const { templateId, preSignedUrl, documentS3Pointer, fields } =
            res.data?.createTemplateBodyUploadUrl;

          const parsedFields: Record<string, string> = JSON.parse(fields);
          const formData = new FormData();
          Object.entries(parsedFields).forEach(([key, value]) => {
            formData.append(key, value);
          });
          formData.append("file", template.body);

          fetch(preSignedUrl, {
            method: "POST",
            body: formData,
          })
            .then((res) => {
              if (!res.ok) {
                setUpdateError(
                  new ApolloError({
                    errorMessage: "Failed to upload template body",
                  }),
                );
                setUpdateLoading(false);
                return;
              }

              updateTemplate({
                variables: {
                  branchId: "branch-1",
                  templateId: templateId!,
                  input: {
                    branchId: "branch-1",
                    id: templateId!,
                    name: template.name,
                    description: template.description,
                    documentS3Pointer,
                  },
                },
              }).then((createdForm) => {
                if (!createdForm.data?.updateTemplate) {
                  setUpdateError(
                    new ApolloError({
                      errorMessage: "Failed to update template",
                    }),
                  );
                  setUpdateLoading(false);
                  return;
                }

                navigate(`/settings/templates`);
              });
            })
            .catch((err) => {
              console.log(err);
              setUpdateError(
                new ApolloError({
                  errorMessage: "Failed to upload template body",
                }),
              );
              setUpdateLoading(false);
            });
        })
        .catch((err) => {
          console.log(err);
          setUpdateError(
            new ApolloError({
              errorMessage: "Failed to create template body upload url",
            }),
          );
          setUpdateLoading(false);
        });
    },
    [updateTemplate, navigate],
  );

  const onDelete = useCallback(() => {
    deleteTemplate({
      variables: {
        branchId: "branch-1",
        templateId: templateId!,
      },
    }).then((result) => {
      if (result.data?.deleteTemplate) {
        navigate(`/settings/templates`);
      }
    });
  }, [deleteTemplate, navigate]);

  const onCancel = () => {
    navigate("/settings/templates");
  };

  return (
    <div className="update-template-page px-4">
      <Helmet>
        <title>Update Template</title>
      </Helmet>
      <div className="text-2xl p-2">Update Template</div>

      {updateLoading && <LoadingOverlay text="Updating template" />}
      {loading && <LoadingOverlay text="Fetching template" />}
      {deleteLoading && <LoadingOverlay text="Deleting template" />}

      {error && <ErrorBanner text={error.message} />}
      {updateError && <ErrorBanner text={updateError.message} />}
      {deleteError && <ErrorBanner text={deleteError.message} />}

      {data && data.getTemplate && body && (
        <TemplateForm
          template={{
            name: data.getTemplate.name,
            description: data.getTemplate.description || undefined,
            body,
          }}
          onCancel={onCancel}
          onSubmit={onSubmit}
          onDelete={onDelete}
        />
      )}
    </div>
  );
}

export default EditTemplate;
