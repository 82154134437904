import React, { PropsWithChildren, useCallback, useRef, useState } from "react";

import "./styles.scss";
import Modal, { ModalFooter } from "../Modal";
import { Button } from "@/components/ui/button";

export type ScenarioNameModalProps = {
  onClose: () => void;
  onConfirm: (name: string) => void;
};

const ScenarioNameModal = ({
  onClose,
  onConfirm,
}: PropsWithChildren<ScenarioNameModalProps>) => {
  const [name, setName] = useState("");

  const nameFieldRef = useRef<HTMLInputElement>(null);
  const focusNameField = () => {
    if (nameFieldRef.current) {
      (nameFieldRef.current as HTMLInputElement).focus();
    }
  };

  React.useEffect(focusNameField, [nameFieldRef]);

  const _onConfirm = useCallback(() => {
    if (name === "") {
      // TODO - visual indicator of issue with name
    } else {
      onConfirm(name);
    }
  }, [onConfirm, name]);

  return (
    <Modal
      title={"Scenario Name"}
      width={400}
      contentHeight={400}
      onClose={onClose}
    >
      <div className="component scenario-name">
        <div className="header relative m-3">
          <input
            className="search-input w-full pl-1 py-1 border rounded-md bg-gray-100 text-sm"
            type="text"
            ref={nameFieldRef}
            onChange={(field) => setName(field.target.value)}
            placeholder="Scenario name"
            value={name}
          />
        </div>
        <ModalFooter className="flex gap-4 justify-end">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={_onConfirm}>Done</Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

export default ScenarioNameModal;
