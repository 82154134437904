import React from "react";

import useDebounce from "../../../hooks/useDebounce";
import { Input } from "@/components/ui/input";

export type DebouncedTextFieldProps = {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDebouncedChange?: (value: string) => void;
  className?: string;
  debounceTime?: number;
  placeholder?: string;
  disabled?: boolean;
};

const DebouncedTextField = React.forwardRef<
  HTMLInputElement,
  DebouncedTextFieldProps
>((props, forwardedRef) => {
  const {
    onChange,
    debounceTime: debounce,
    onDebouncedChange,
    ...inputProps
  } = props;

  const { onChange: debouncedInputChanged } = useDebounce(
    debounce || 1000,
    onDebouncedChange,
  );

  return (
    <div className="component debounced-text-field">
      <Input
        ref={forwardedRef}
        type="text"
        {...inputProps}
        onChange={(event) => {
          onChange(event);
          debouncedInputChanged(event);
        }}
      />
    </div>
  );
});

export default DebouncedTextField;
