import { combineLatest, map, Observable } from "rxjs";
import Decimal from "decimal.js-light";
import { AmortizationSummary, CommercialIndustrialDependencies } from "../core";
import moment from "moment";

import {
  IncomeStatement,
  IncomeStatementOutput,
} from "../../../incomeStatement";
import mapAllDefined from "../../../util/operators/mapAllDefined";
import { FundingCurveSnapshot, getRateAtDate } from "../core/FundingCurve";
import { Index } from "../core/IndexRates";

export type IncomeStatementInput = {
  amortizationSummary: Observable<AmortizationSummary | undefined>;
  termYears: Observable<number | undefined>;
  originationFees: Observable<Decimal | undefined>;
  originationExpenses: Observable<Decimal | undefined>;
  index: Observable<Index | undefined>;
  monthlyServicingCost: Observable<Decimal | undefined>;
  fundingCurveSnapshot: Observable<FundingCurveSnapshot | undefined>;
};

export type IncomeStatementModel = {
  input: IncomeStatementInput;
  output: IncomeStatementOutput;
};

export const calculate = (
  input: IncomeStatementInput,
  dependencies: Pick<
    CommercialIndustrialDependencies,
    "taxRate" | "startDate" | "minimumCapital" | "minimumLoanLoss"
  >,
): IncomeStatementOutput => {
  const {
    amortizationSummary,
    termYears,
    originationFees,
    originationExpenses,
    index,
    monthlyServicingCost,
    fundingCurveSnapshot,
  } = input;
  const { startDate, taxRate, minimumCapital, minimumLoanLoss } = dependencies;

  const feeOrInterestIncome = combineLatest([
    amortizationSummary,
    termYears,
    originationFees,
    originationExpenses,
  ]).pipe(
    mapAllDefined(
      ([
        amortizationSummary,
        termYears,
        originationFees,
        originationExpenses,
      ]) =>
        amortizationSummary.interestPaid
          .plus(originationFees)
          .minus(originationExpenses)
          .dividedBy(termYears),
    ),
  );

  const feeOrInterestExpense = combineLatest([
    amortizationSummary,
    termYears,
    fundingCurveSnapshot,
  ]).pipe(
    mapAllDefined(([amortizationSummary, termYears, fundingCurveSnapshot]) => {
      const dateAtMaturity = moment(startDate).add(termYears, "years").toDate();
      const costOfFunds = getRateAtDate(
        fundingCurveSnapshot.fundingCurve,
        dateAtMaturity,
      );
      return amortizationSummary.averageBalance.mul(costOfFunds);
    }),
  );

  const netFeeOrInterestIncome = combineLatest([
    feeOrInterestIncome,
    feeOrInterestExpense,
  ]).pipe(
    mapAllDefined(([feeOrInterestIncome, feeOrInterestExpense]) =>
      feeOrInterestIncome.minus(feeOrInterestExpense),
    ),
  );

  const nonFeeOrInterestExpense = monthlyServicingCost.pipe(
    map((monthlyServicingCost) => monthlyServicingCost?.mul(12)),
  );

  const pretaxIncome = combineLatest([
    netFeeOrInterestIncome,
    nonFeeOrInterestExpense,
  ]).pipe(
    mapAllDefined(([netFeeOrInterestIncome, nonFeeOrInterestExpense]) =>
      netFeeOrInterestIncome.minus(nonFeeOrInterestExpense),
    ),
  );

  const taxes = pretaxIncome.pipe(
    map((pretaxIncome) => pretaxIncome?.mul(taxRate)),
  );

  const netIncome = combineLatest([pretaxIncome, taxes]).pipe(
    mapAllDefined(([pretaxIncome, taxes]) => pretaxIncome.minus(taxes)),
  );

  const averageBalance = amortizationSummary.pipe(
    map((amortizationSummary) => amortizationSummary?.averageBalance),
  );

  const loanLossReserves = averageBalance.pipe(
    map((averageBalance) => averageBalance?.mul(minimumLoanLoss)),
  );

  const averageEquity = averageBalance.pipe(
    map((averageBalance) => averageBalance?.mul(minimumCapital)),
  );

  const returnOnAssets = combineLatest([netIncome, averageBalance]).pipe(
    mapAllDefined(([netIncome, averageBalance]) =>
      netIncome.dividedBy(averageBalance),
    ),
  );

  const returnOnEquity = combineLatest([netIncome, averageEquity]).pipe(
    mapAllDefined(([netIncome, averageEquity]) =>
      netIncome.dividedBy(averageEquity),
    ),
  );

  return {
    feeOrInterestIncome,
    feeOrInterestExpense,
    netFeeOrInterestIncome,
    nonFeeOrInterestExpense,
    loanLossReserves,
    pretaxIncome,
    taxes,
    netIncome,
    averageBalance,
    averageEquity,
    returnOnAssets,
    returnOnEquity,
  };
};

export const fromIncomeStatement = (
  incomeStatement: IncomeStatement | undefined,
  amortizationSummary: Observable<AmortizationSummary | undefined>,
  termYears: Observable<number | undefined>,
  originationFees: Observable<Decimal | undefined>,
  originationExpenses: Observable<Decimal | undefined>,
  index: Observable<Index | undefined>,
  monthlyServicingCost: Observable<Decimal | undefined>,
  fundingCurveSnapshot: Observable<FundingCurveSnapshot | undefined>,
  dependencies: Pick<
    CommercialIndustrialDependencies,
    "taxRate" | "startDate" | "minimumCapital" | "minimumLoanLoss"
  >,
): IncomeStatementModel => {
  const input: IncomeStatementInput = {
    amortizationSummary,
    termYears,
    originationFees,
    originationExpenses,
    index,
    monthlyServicingCost,
    fundingCurveSnapshot,
  };

  const output = calculate(input, dependencies);

  return {
    input,
    output,
  };
};
